@import "base/common-dependencies";

$gw-premises-summary-heading-font-color: $grey-dark-90-color !default;
$gw-premises-summary-icon-color: $grey-dark-90-color !default;
$gw-premises-summary-contents-background-color: $grey-light-10-color !default;
$gw-premises-summary-icon-default-color: $grey-light-60-color !default;
$gw-premises-summary-heading-vertical-height: 22px !default;
$gw-premises-summary-heading-text-size: 15px !default;
$gw-premises-summary-panel-size-margin: 2px 0;
$gw-premises-summary-header-margin: 0 $x*2;

.gwPremisesSummary {
  &__panel {
    margin: $gw-premises-summary-panel-size-margin;
  }

  &__header {
    display: flex;
    justify-content: flex-start;
    padding: $gw-premises-summary-header-margin;
    cursor: pointer;
    min-height: $gw-premises-summary-heading-vertical-height;
    align-items: baseline;

    &:hover {
      background: $gw-premises-summary-contents-background-color;
    }

    &__wide {
      padding: $x $x*3;

      @include media('<=phonePortrait') {
        flex-direction: column;
        margin: 0;
      }
    }

    &__heading,
    &__subheading {
      flex: 0 0 33%;
      font-size: $gw-premises-summary-heading-text-size;
      line-height: 1.47;
      color: $gw-premises-summary-heading-font-color;
      vertical-align: top;
    }

    &__heading {
      font-weight: bold;
    }

    &__toggle {
      padding: 0 $x*2;
    }

    &__typeIcon {
      position: inherit;
      margin: 0;
      width: $x*4;
      color: $gw-premises-summary-icon-default-color;

      &_colorless {
        color: $gw-premises-summary-icon-color;
      }
    }

    &__action {
      flex: 1 0 auto;
      padding: 0 0 0 1.5*$x;
      margin: 0 0 0 $x;
      font-size: 20px;
      font-weight: normal;
      text-align: right;
      color: $gw-premises-summary-heading-font-color;
      transition: all 0.2s ease-out;
    }
  }

  &__contents {
    padding: 0 $x*3;
    background: $gw-premises-summary-contents-background-color;
  }
}
