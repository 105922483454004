@import "base/common-dependencies";

$gtp-typeahead-container-margin-bottom: $x !default;
$gtp-text-top-margin: 1.5em !default;

.gwGtpTitlePosition {
  width: 100%;
  line-height: $gw-gtp-panel-title-line-height;
}

.gwGtpTextPosition {
  margin-top: $gtp-text-top-margin;
  line-height: $gw-gtp-panel-title-line-height;
  width: 100%;
}

.gwGtpTypeaheadContainer {
  margin-bottom: $gtp-typeahead-container-margin-bottom;
  width: 100%;
}

.gwGtpContinueButton {
  margin-bottom: $gw-gtp-continue-button-bottom-margin;
}
