@import "base/common-dependencies";

/* --- YOUTUBE --- */
$faq-youtube-video-width: 400px !default;
$faq-youtube-video-height: 224px !default;

.gwFaq {
  @include otherSection($topIndent: 10px);
}

.gwFaqSection {
  color: $grey-dark-70-color;
  margin-top: 2 * $x;
  margin-bottom: 2 * $x;
}

.gwFaqEmptyInfo {
  color: $grey-dark-70-color;
  text-align: center;
}

.gwFaqSearchSection {
  margin-bottom: 3 * $x;
}

.gwFaqTopicSection {
  margin-top: 1 * $x;
  margin-bottom: 1 * $x;
  margin-left: 3 * $x;

  ul {
    list-style: disc inside;
  }
}

.gwFaqYoutube {
  max-width: $faq-youtube-video-width;
  max-height: $faq-youtube-video-height;
  width: 100%;
  height: 50vw;
  margin: auto;
  display: block;
}

.gwFaqAccordionGroup {
  background-color: $wizard-sidebar-bg;
  padding: 1*$x;
  margin-bottom: 1*$x;
}

.gwFaqTopicTitle {
  font-size: $gw-base-font-size + 0.5;
}
