@import "base/common-dependencies";

$contents-padding: 0 0 0 $x*4;
$contents-margin: $x 0 0 0;

.gwCPBuildingSummary {
  margin: 0;
  font-size: $gw-base-font-size;
  font-weight: normal;

  h2 {
    margin-bottom: $x/4;
    color: $grey-dark-60-color;
    font-size: ($gw-base-font-size/1.2);
    font-weight: normal;
  }

  &_details {
    display: flex;
    justify-content: flex-start;

    @include media('<=tabletPortrait') {
      flex-direction: column;
    }
  }

  &_info {
    flex: 0 1 33%;
    margin: $contents-margin;
    padding: $contents-padding;
  }

  &_yearBuilt,
  &_constructionType,
  &_propertyClass,
  &_basisAmount,
  &_noOfStories,
  &_totalArea,
  &_exposure,
  &_sprinkler,
  &_alarm,
  &_editLink {
    padding-bottom: $x*2;
  }
}
