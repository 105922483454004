@import "base/common-dependencies";

$gw-mobile-notification-h-padding: $x*2 !default;
$gw-mobile-notification-v-padding: $x !default;

.notification {
  position: fixed;
  left: 0;
  right: 0;
  top: 100%;
  bottom: auto;
  background: $gw-mobile-notification-color;
  color: $grey-light-10-color;
  padding: $gw-mobile-notification-v-padding $gw-mobile-notification-h-padding;
  will-change: transform;
  transform: translateY(0);
  transition-property: transform;
  transition-duration: 0.2s;
  transition-timing-function: linear;

  &_open {
    transform: translateY(-100%);
  }

  &__displacededstuff {
    position: absolute;
    left: 0;
    right: 0;
    transform: translateY(calc(-100% - #{$gw-mobile-notification-v-padding}));
  }
}
