@import "base/common-dependencies";
@import "default-styles/common/form-validation-definitions.scss";

$gwCoverageBackgroundColor: $white-color !default;

$gwCoverageHInnerOffset: $x*3 !default;
$gwCoverageVInnerOffset: $x*3 !default;

$gwCoverageVOuterOffset: $x !default;

$gwCoverageBorderColor: $table-border !default;
$gwCoverageBorderRadius: $baseBorderRadius !default;

$gwExplainIconColor: $brand-alt-01 !default;
$gwExplainIconHoverColor: $core-product-color !default;

.gwEditableCoverages {
  @mixin animate() {
    &:global(.ng-enter),
    &:global(.ng-leave) {
      transition: all 0.15s linear;
      transition-property: transform, opacity, max-height;
      overflow: hidden;
    }

    &:global(.ng-enter),
    &:global(.ng-leave.ng-leave-active) {
      opacity: 0;
      max-height: 0;
      transform: translateY(-$x);
    }

    &:global(.ng-leave),
    &:global(.ng-enter.ng-enter-active) {
      opacity: 1;
      max-height: 999px;
      transform: translateY(0);
    }
  }

  &__coverage {
    background-color: $gwCoverageBackgroundColor;
    border: 1px solid $gwCoverageBorderColor;
    border-radius: $gwCoverageBorderRadius;
    padding: $gwCoverageVInnerOffset $gwCoverageHInnerOffset;

    &:not(:first-child) {
      margin-top: $gwCoverageVOuterOffset;
    }

    display: flex;
    flex-direction: row;

    &_clickable {
      cursor: pointer;
    }
  }

  &__coverage[data-flavor=material] {
    --gw-editable-coverage-border: 1px solid var(--gw-grey-light-4);

    background-color: transparent;
    border: var(--gw-editable-coverage-border);
  }

  &__coverageCheckbox {
    flex-shrink: 1;

    &_placeholder {
      visibility: hidden;
    }
  }

  &__coverageContent {
    flex-grow: 1;
    position: relative;
    margin-bottom: -$controlGroupBottomIndent; //Account for ctrl-group indents
  }

  &__heading {
    display: flex;
    flex-direction: row;
    padding-bottom: $controlGroupBottomIndent;
  }

  &__headingLabel {
    margin-right: 1em; //Leave space for explain icon on the right (~1 character wide)
  }

  &__headingLabelClickarea {
    display: inline-block;
    cursor: pointer;
  }

  &__headingTitle {
    margin-right: ($x/2);
  }

  &__headingPrice {
    display: inline-block;
    vertical-align: top; //So that it doesn't jumps during animation

    @include animate();
  }

  &__explain {
    cursor: default;
    position: absolute;
    right: 0;
    color: $gwExplainIconColor;

    &:hover {
      color: $gwExplainIconHoverColor;
    }
  }

  &__terms {
    @include animate();
  }
}
