@import "base/common-dependencies";
@import "../common/tile-variables";
@import "../common/tile-mixins";

// Generate navigation flavor classes for each tile type
@each $item, $color in $tile-colors {
  .gw-tile-container__navigation#{$item + 'Selectable'} {
    @include gw-tile-container-default();
    @include gw-tile-container-navigation();
    @include gwTileSelectableBackground($color);
    @include selectableTile();

    color: $color;

    &:hover {
      .gw-tile-header__navigation#{$item + 'Selectable'} {
        height: 2 * $x;
      }
    }
  }

  .gw-tile-container__navigation#{$item} {
    @include gw-tile-container-default();
    @include gw-tile-container-navigation();

    color: $color;
    position: relative;
  }

  .gw-tile-content-container__navigation#{$item} {
    @include gw-tile-content-container__default();

    position: relative;
    height: $gw-tile-navigation-size;
    width: $gw-tile-navigation-size;
    border-radius: $gw-tile-navigation-header-radius;
  }

  .gw-tile-container__navigation#{$item + 'Active'} {
    @include gwTileActiveBackground($color);
    @include gw-tile-container-navigation();

    position: relative;
    color: $white-color;
    border-radius: $gw-tile-container-border-radius;

    &::after {
      content: "";
      border-top-color: $color;
      border-style: solid;
      border-width: #{$gw-tile-completed-indicator-box-height/2};
      margin-left: calc(50% - #{$gw-tile-completed-indicator-box-height/2});
      position: relative;
      bottom: -#{$gw-tile-completed-indicator-box-height/2};
    }
  }

  .gw-tile-header__navigation#{$item + 'Selectable'} {
    left: 0;
    background: $color;
    position: absolute;
    width: 100%;
    height: $x;
    border-radius: $gw-tile-navigation-header-radius;
  }
}
