@import "base/common-dependencies";

$gw-multi-quote-cell-padding-default: $x * 3 !default;
$gw-multi-quote-cell-padding-small-desktop: $x * 2 !default;
$gw-multi-quote-cell-padding-tablet: $x !default;
$gw-multi-quote-info-position: 25px !default;
$gw-multi-quote-info-border-radius: 50% !default;
$gw-multi-quote-info-right: - ($x * 3);

.gw_quote_container {
  position: relative;

  @for $i from 1 through 5 {
    li:first-child:nth-last-child(#{$i}),
    li:first-child:nth-last-child(#{$i}) ~ li {
      width: #{100%/$i};
    }
  }
}

.gw_quote_row {
  display: flex;
  width: 100%;
  position: relative;
}

.gw_quote_cell {
  border: 1px solid $grey-light-30-color;
  padding: $gw-multi-quote-cell-padding-default;
  text-align: left;
  display: flex;
  flex-wrap: wrap;
  word-break: break-word;

  @include media('<=desktopSmall') {
    padding: $gw-multi-quote-cell-padding-small-desktop;
  }

  @include media('<=tabletPortrait') {
    padding: $gw-multi-quote-cell-padding-tablet;
  }
}

.gw_quote_cell_col_inner {
  border-right: none;
}

.gw_quote_cell_row_inner {
  border-bottom: none;
}

.gw_quote_cell_info {
  display: none;
}

.gw_quote_cell_button {
  position: absolute;
  cursor: pointer;
  top: $gw-multi-quote-info-position;
  height: $x * 3;
  width: $x * 3;
  background: $grey-light-30-color;
  color: $link-color;
  border-top-right-radius: $gw-multi-quote-info-border-radius;
  border-bottom-right-radius: $gw-multi-quote-info-border-radius;
  font-size: $font-l;
  border: none;
  padding: 0;
  right: $gw-multi-quote-info-right;
}

.gw_quote_cell_button_active {
  background: $link-color;
  color: $white-color;
}

.gw_quote_cell_inner_open {
  background: linear-gradient(to bottom, $grey-light-20-color, $white-color);
  border-bottom: none;
  display: inline;
  text-align: center;
  width: 100%;
}

.gw_quote_cell_inner_link {
  color: $link-color;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}

.gw_quote_view_cell {
  width: 100%;
}

.gw_quote_cell_help_open {
  border-top: none;
}
