@import "base/common-dependencies";

.header {
  color: $white-color;
  font-size: $font-xl;
  display: flex;
  align-items: center;
  text-align: center;
  position: relative;
  height: $headerHeight;
  background-color: $header-bg;
  padding-right: $headerHorPadding;

  &__back {
    height: 100%;
    padding-left: $headerHorPadding;
    display: flex;
    align-items: center;
  }

  &__title {
    margin: auto;
  }
}
