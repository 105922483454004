@import "base/common-dependencies";

$gw-gtp-title-position-top-margin: $x;

.gwGtpManualEntryLink {
  margin-top: 2 * $x;
}

.gwGtpTitlePosition {
  width: 100%;
  line-height: $gw-gtp-panel-title-line-height;

  @include media('<=phoneLandscape') {
    margin-top: $gw-gtp-title-position-top-margin;
  }
}

.gwGtpAddressContainer {
  height: 100%;

  @include media('<=phoneLandscape') {
    flex-wrap: nowrap;
  }
}

.gwGtpAddressReadonly {
  display: flex;
}

.gwGtpContinueButton {
  margin-bottom: $gw-gtp-continue-button-bottom-margin;
}
