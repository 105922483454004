@import "base/common-dependencies";

$gw-input-select-row-padding: $x * 3;
$gw-input-select-row-padding-mobile: $x * 2;
$gw-input-select-lr-padding: $x*2;
$gw-multiselect-icon-padding: 0 $gw-input-select-lr-padding 0 $gw-input-select-lr-padding;
$gw-multiselect-icon-width: 7 * $x;
$gw-multiselect-help-desktop-tap-area: $x * 4;
$gw-multiselect-help-mobile-tap-area: ($x * 6.5);

@mixin gwInputSelectRows() {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  padding: $gw-input-select-row-padding;
  margin-top: $x;
  margin-bottom: $x;
  border-radius: $baseBorderRadius;
  border: 1px solid;

  &:hover {
    cursor: pointer;
  }

  @include media('<=phoneLandscape') {
    padding: $gw-input-select-row-padding-mobile;
  }
}

.gw_multiselect_row_container {
  @include gwInputSelectRows();

  border-color: $radioSelectBorderColor;

  &:hover {
    border-color: $wizard-sidebar-steps-current;
  }
}

.gw_multiselect_row_container__selected {
  @include gwInputSelectRows();

  background-color: $wizard-sidebar-bg;
  border-color: $wizard-sidebar-bg;
}

.gw_multiselect_label {
  font-size: $font-xl;
  line-height: $base-line-height;
  font-weight: $gw-font-weight-regular;

  @include media('<=phoneLandscape') {
    font-size: $gw-base-font-size;
    margin-right: $gw-multiselect-help-mobile-tap-area;
  }
}

.gw_multiselect_help {
  font-size: $gw-base-font-size;
  line-height: $base-line-height;
  font-weight: $gw-font-weight-regular;
  margin-top: $x;
  padding-left: $gwCheckInputSize + $gw-multiselect-icon-width + $x;

  @include media('<=phoneLandscape') {
    padding-left: $gwCheckInputSize + ($x * 3);
  }
}

.gw_multiselect_icon {
  padding: $gw-multiselect-icon-padding;
  font-size: $font-xl;
  width: $gw-multiselect-icon-width;
}

.gw_multiselect_help_icon {
  position: absolute;
  color: $link-color;
  right: 2 * $x;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: $gw-multiselect-help-desktop-tap-area;
  height: $gw-multiselect-help-desktop-tap-area;

  @include media('<=phoneLandscape') {
    width: $gw-multiselect-help-mobile-tap-area;
    height: $gw-multiselect-help-mobile-tap-area;
    top: 0;
    right: 0;
  }
}

.gw_multiselect_row_first_line {
  width: 100%;
  display: flex;
}
