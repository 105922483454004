@import "base/common-dependencies";

$gw-override-btn-width: 85px !default;
$gw-override-btn-top-margin: 1.5rem !default;
$gw-start-button-container-bottom-margin: $x*2 !default;

.gwWideButton {
  margin-top: $gw-override-btn-top-margin;
  width: $gw-override-btn-width;
}

.gwStartButtonContainer {
  margin-bottom: $gw-start-button-container-bottom-margin;
}

.gwRetrieveQuoteLink {
  text-decoration: underline;
}

// Hiding the gw-pl-input-ctrl default label and mandatory asterix
.gwHideLabel {
  label {
    display: none;
  }
}
