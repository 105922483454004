@import "base/common-dependencies";
@import "../../common/tile-variables";
@import "../../common/tile-mixins";

// Wizard Tile
.gw-tile-container__wizardDefaultSelectable {
  @include gw-tile-container-default($gw-tile-wizard-size);
  @include gwTileSelectableBackground($active-color);
  @include gwTileWizardBorder($active-color);
  @include selectableTile();
}

.gw-tile-container__wizardDefaultCompleted {
  @include gw-tile-container-default($gw-tile-wizard-size);
  @include gwTileSelectableBackground($link-color);
  @include gwTileWizardBorder($link-color);
  @include selectableTile();
  @include wizardTileTail();

  &::after {
    // Generates the circular check box on the tail of completed tiles
    line-height: $gw-tile-completed-indicator-box-height;
    font-size: $gw-root-font-size;
    font-family: FontAwesome;
    content: "\f00c"; // Check from font awesome
    color: $white-color;
    border-radius: 50%;
    background-color: mix($link-color, $white-color, 50%);
    position: absolute;
    bottom: -#{$gw-tile-completed-indicator-box-height/2};
    left: calc(50% - #{$gw-tile-completed-indicator-box-height/2});
  }
}

.gw-tile-container__wizardDefaultDisabled {
  @include gw-tile-container-default($gw-tile-wizard-size);

  color: $black-color;
  position: relative;
  border: 1px solid $grey-light-40-color;
}

.gw-tile-content-container__wizardDefault {
  padding: $x;
  position: relative;
  overflow: hidden;
  height: $gw-tile-wizard-size;
  width: $gw-tile-wizard-size;
}

.gw-tile-container__wizardDefaultActive {
  @include gwTileActiveBackground($grey-dark-70-color);
  @include wizardTileTail();

  position: relative;
  width: $gw-tile-wizard-size;
  color: $white-color;
  border-radius: $gw-tile-wizard-header-radius;

  &::after {
    content: "";
    border-top-color: $grey-dark-70-color;
    border-style: solid;
    border-width: #{$gw-tile-completed-indicator-box-height/2};
    margin-left: calc(50% - #{$gw-tile-completed-indicator-box-height/2});
  }
}

// Flavors
.gw-tile-container__wizardSmallSelectable {
  @include gw-tile-container-default();
  @include gwTileSelectableBackground($active-color);
  @include gwTileWizardBorder($active-color);
  @include selectableTile();
}

.gw-tile-container__wizardSmallCompleted {
  @include gw-tile-container-default();
  @include gwTileSelectableBackground($link-color);
  @include gwTileWizardBorder($link-color);
  @include selectableTile();
  @include wizardTileTail();

  &::after {
    // Generates the circular check box on the tail of completed tiles
    line-height: $gw-tile-completed-indicator-box-height;
    font-size: $gw-root-font-size;
    font-family: FontAwesome;
    content: "\f00c"; // Check from font awesome
    color: $white-color;
    border-radius: 50%;
    background-color: mix($link-color, $white-color, 50%);
    margin-top: calc(100% - #{$gw-tile-completed-indicator-box-height/2});
  }
}

.gw-tile-container__wizardSmallDisabled {
  @include gw-tile-container-default();

  color: $black-color;
  position: relative;
  border: 1px solid $grey-light-40-color;
}

.gw-tile-content-container__wizardSmall {
  padding: $x;
  position: relative;
  overflow: hidden;
  height: $gw-tile-wizard-small-size;
  width: $gw-tile-wizard-small-size;
}

.gw-tile-container__wizardSmallActive {
  @include gwTileActiveBackground($grey-dark-70-color);
  @include wizardTileTail();

  position: relative;
  color: $white-color;
  border-radius: $gw-tile-wizard-header-radius;

  &::after {
    content: "";
    border-top-color: rgba($grey-dark-70-color, $gw-active-tile-bottom-alpha);
    border-style: solid;
    border-width: #{$gw-tile-completed-indicator-box-height/2};
    margin-left: calc(50% - #{$gw-tile-completed-indicator-box-height/2});
  }
}
