@import "base/common-dependencies";

$media-text-padding: 6px !default;
$media-text-border: solid 1px $media-text-border-color !default;
$media-text-border-radius: 3px !default;
$media-text-not-last-section-border-bottom: solid 1px $media-text-border-color !default;

.gwMediaText {
  background: $media-text-background;
  padding: $media-text-padding;
  border: $media-text-border;
  border-radius: $media-text-border-radius;

  @include clearfix;
}

.gwMediaTextSection li {
  padding-bottom: $x * 2;

  &:not(:last-child) {
    margin-bottom: $x * 2;
    border-bottom: $media-text-not-last-section-border-bottom;
  }
}
