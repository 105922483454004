@import "base/common-dependencies";

$media-rss-border-radius: 3px !default;
$media-rss-internal-offsets: 6px !default;
$media-rss-fav-offset: 10px !default;
$media-rss-min-height: 4 * $gw-base-font-size !default;

.gw-media-rss {
  background: $media-rss-background;
  border-radius: $media-rss-border-radius;
  border: 1px solid $media-rss-border-color;
  min-height: $media-rss-min-height;

  &-header {
    padding: $media-rss-internal-offsets;
    border-bottom: 1px solid $media-rss-title-divider-color;
  }

  &-favicon {
    float: left;
    margin-right: $media-rss-fav-offset;
  }

  &-entry {
    margin: $media-rss-internal-offsets;

    &:not(:first-child) {
      padding-top: nth($media-rss-internal-offsets, 1);
      border-top: 1px solid $media-rss-list-divider-color;
    }
  }

  &-timestamp {
    color: $media-rss-secondary-text-color;
    font-size: $font-xs;
    cursor: default;
  }

  &-link {
    color: $media-rss-link-color;
    font-size: $font-m;
    padding: 3px 0; //No blinking when moving mouse across lines of a long link

    &:hover {
      color: $media-rss-link-color;
    }
  }
}
