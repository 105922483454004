
@import "base/common-dependencies";

$color_blue: $gw-gateway-cobranded-main-colour;

.info_box {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border: 1px solid $color_blue;
  margin-bottom: 20px;

  &__content {
    display: flex;
    flex-direction: row;
    font-weight: bold;
    min-height: 100px;
    max-height: 400px;

    &__image {
      min-width: 0;
      max-width: 150px;
      align-self: center;
      justify-self: center;
    }

    &__message {
      margin-left: 30px;
      margin-right: 30px;
      flex: 1;
      align-self: center;
      font-size: 2rem;
      line-height: 1.2em;
      word-break: break-word;
    }
  }

  &__action {
    align-self: flex-end;

    &__btn {
      font-size: 1.5rem;
      color: $color_blue;
    }
  }
}