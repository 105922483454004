@import "base/common-dependencies";

$media-contact-border-radius: 3px !default;
$media-contact-main-font-size: 12px !default;
$media-contact-name-font-size: 15px !default;
$media-contact-default-photo-size: 64px !default;
$media-contact-photo-offset: 6px !default;
$media-contact-padding: 6px !default;

gw-media-contact:not(:empty) {
  background: $media-contact-background;
  border-radius: $media-contact-border-radius;
  border: solid 1px $media-contact-border-color;
  padding: $media-contact-padding;

  @include clearfix;

  .gwName {
    color: $media-contact-name-color;
    font-weight: bold;
    font-size: $media-contact-name-font-size;
  }

  .gwAddInfo {
    overflow: hidden;
    font-size: $media-contact-main-font-size;

    .gwRole {
      color: $media-contact-role-color;

      &::first-letter {
        text-transform: uppercase;
      }
    }

    .gwEmail {
      display: inline-block;
      color: $media-contact-email-color;
      word-wrap: break-word;
      word-break: break-all;
    }

    .gwOther {
      color: $media-contact-secondary-text-color;
    }
  }

  .gwPhoto {
    float: right;
    width: $media-contact-default-photo-size;
    height: $media-contact-default-photo-size;
    overflow: hidden;
    position: relative;
    margin-left: $media-contact-photo-offset;
    text-align: center;

    i {
      width: 100%;
      color: $media-contact-default-photo-foreground;
      font-size: $media-contact-default-photo-size;
      background-color: $media-contact-default-photo-background;
    }
  }
}
