@import "base/common-dependencies";

$gw-spreadsheet-upload-result-icon-size: 20px !default;
$gw-spreadsheet-upload-result-section-bottom-spacing: 10px !default;
$gw-spreadsheet-upload-result-report-width: 100% !default;
$gw-spreadsheet-upload-result-numeric-font-size: 20px !default;

.gw_spreadsheet_upload_result {
  background-color: #0b4d48;

  &__alert {
    .body {
      padding: $x*5 0 $x*4 $x*4.25;
    }

    .subheading {
      margin: $x*1.5 0 $x*3 0;
    }

    .summary {
      margin: $x*1.5 0 $x*3 0;

      .section {
        display: flex;
        align-items: center;
        margin-bottom: $gw-spreadsheet-upload-result-section-bottom-spacing;

        .icon {
          min-width: $gw-spreadsheet-upload-result-icon-size;
          margin-right: $x*2;
          font-size: $gw-spreadsheet-upload-result-icon-size;

          .success {
            color: $active-color;
          }

          .error {
            color: $alert-color;
          }
        }

        .report {
          width: $gw-spreadsheet-upload-result-report-width;

          .linkDisabled {
            cursor: not-allowed;
            pointer-events: none;
            color: grey;
          }

          .numbers {
            font-size: $gw-spreadsheet-upload-result-numeric-font-size;
          }

          .errors {
            .error_descriptor {
              color: $grey-dark-60-color;

              .error-details-numbers {
                color: $grey-dark-90-color;
              }
            }

            .error_description {
              line-height: $x*2;
            }
          }
        }
      }
    }

    .contents {
      margin-bottom: $x*3;
    }

    .successInstructionList {
      margin: $x*2 0 $x*4 0;

      .strongText {
        font-weight: bold;
      }
    }
  }
}
