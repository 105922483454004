@import "base/common-dependencies";

$gw-zip-form-top-bottom-padding: 20px !default;
$gw-zip-form-left-right-padding: $x*2 !default;
$gw-zip-form-button-top-margin: $x*8 !default;
$gw-zip-form-width: 250px !default;
$gw-product-icon-container: $x*5 !default;

.gwZipForm {
  padding: $gw-zip-form-top-bottom-padding $gw-zip-form-left-right-padding;
  height: auto;
  width: $gw-zip-form-width;
  text-align: center;

  @include media('<=phoneLandscape') {
    border-bottom: 1px solid $theme-color;
  }

  .gwProductIconContainer {
    min-height: $gw-product-icon-container;
    font-size: $font-xxl;
    padding-bottom: 2 * $x;
  }

  .gwProductTitleContainer {
    font-size: $font-xl;
  }

  .gwProductHelpTextContainer {
    font-weight: $gw-font-weight-light;
    margin: $x*2 0;
  }

  .gwOverrideBtnStyle {
    // gtp & slq panels do not have the zip code field. Adding top margin to align this button with other 'start quote' buttons.
    margin-top: $gw-zip-form-button-top-margin;
  }
}

.gwDisablePanel {
  display: none;
}
