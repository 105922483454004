@import "base/common-dependencies";

$radius: $x;
$transitionTime: 0.25s;
$indentBetweenItems: 5%;
$indentBetweenLines: 20px;
$construction-accordion-heading-bg: $theme-color;
$construction-validation-sign-size: $font-xl !default;
// items sizes
$itemsPerLine: 4;
$itemsPerLineMobile: 2;
$itemWidth: 100%/$itemsPerLine - $indentBetweenItems;
$itemWidthMobile: 100%/$itemsPerLineMobile - $indentBetweenItems;
$image-size: 150px;

$radioMediaWrapperMaxWidth: 10 * $x;
$radioMediaWrapperLeftSpacing: 10%;
$radioContentMediaBoxShadow: 10px 10px 15px -4px rgba($black-color, 0.75);
$radioTextPaddingTop: 10px;
$radioTextMaxWidth: 100px;

.gwRadioSelectImage {
  width: 100% + $indentBetweenItems;
  margin: 0 (-$indentBetweenItems);

  @include clearfix;

  font-size: 0; // to prevent indents between inline-block elements

  .gwRadioLabel {
    @include media('<=phoneLandscape') {
      width: $itemWidthMobile;
    }

    width: $itemWidth;
    margin-left: $indentBetweenItems;
    margin-bottom: $indentBetweenLines;
    cursor: pointer;
    display: inline-block;
    vertical-align: top;
    font-size: $gw-base-font-size; // to prevent indents between inline-block elements

    &.gwOtherOption {
      // RESET
      padding-top: 0;
    }

    // SELECTED STYLES
    input[type=radio] {
      @include form-element-hidden;

      &:checked + .gwRadioContent {
        // --- HERE GO STYLES FOR SELECTED BOX ---

        .gwRadioMediaWrapper::after {
          opacity: 0;
        }

        .gwRadioContentMedia {
          box-shadow: 10px 10px 15px -8px rgba($black-color, 0.75);
        }

        .gwRadioIcon {
          opacity: 1;
        }
      }
    }

    .gwRadioContent {
      text-align: center;

      .gwRadioMediaWrapper {
        max-width: $radioMediaWrapperMaxWidth; // makes media to be not wider than some value
        margin-left: $radioMediaWrapperLeftSpacing;
        position: relative;

        &::before {
          display: block;
          content: " ";
          width: 100%;
          padding-top: 100%;
        }

        &::after {
          display: block;
          content: '';
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0; // to take all width
          left: 0; // to take all height
          border-radius: $radius;
          opacity: 0.3;
          background-color: $white-color;
          transition-property: opacity;
          transition-duration: $transitionTime;
        }

        &:hover {
          &::after {
            opacity: 0;
          }
        }

        // IMAGE
        .gwRadioContentMedia {
          @include absolute-position;

          background-color: $grey-color;
          box-shadow: $radioContentMediaBoxShadow;
          transition-property: box-shadow;
          transition-duration: $transitionTime;
          border-radius: $radius;
        }

        // ICON
        .gwRadioIcon {
          $indent: 10%;

          opacity: 0;
          position: absolute;
          right: $indent;
          bottom: $indent;
          font-size: $construction-validation-sign-size;
          transition-property: opacity;
          transition-duration: $transitionTime;
          color: $white-color;
          background-color: $construction-accordion-heading-bg;
          border-radius: $radius/2;
        }
      }

      // TEXT
      .gwRadioText {
        padding-top: $radioTextPaddingTop;
        max-width: $radioTextMaxWidth; // keeps the title following the size of icon
      }
    }
  }
}
