@import "base/common-dependencies";

$gw-multi-quote-cell-offering-padding: $x * 3 !default;
$gw-multi-quote-cell-offering-padding-tablet: $x * 2 !default;
$gw-binary-choice-container-padding: 30px !default;
$gw-uw-issues-warning-icon-right-padding: $x !default;
$gw-uw-issues-warning-icon-font-size: $font-l !default;
$gw-uw-issues-warning-text-font-size: $gw-base-font-size !default;
$gw-hide-icon-gradient: linear-gradient(to bottom, rgba($white-color, 1) 90%, rgba($white-color, 0) 100%) !default;
$gw-price-diff-indicator-height: 30px !default;
$gw-price-diff-indicator-width: 60px !default;
$gw-indicator-animation-delay-seconds: 2s !default;

.gw_yearly_monthly_price_labels {
  font-size: $gw-base-font-size;
}

.gw_binary_choice_container {
  margin-bottom: $gw-binary-choice-container-padding;
  text-align: center;
}

.gw_quote_table {
  display: flex;
  flex-wrap: wrap;
}

.gw_quote_row {
  display: flex;
  width: 100%;
  position: relative;
}

.gw_quote_cell_offering {
  display: flex;
  flex-direction: column;
  text-align: center;
  border: 1px solid lightgrey;
  padding: $gw-multi-quote-cell-offering-padding;
  width: 100%;

  @include media('<=tabletLandscape') {
    padding: $gw-multi-quote-cell-offering-padding-tablet;
  }
}

.gw_quote_offering_header {
  text-align: center;
  position: sticky;
  top: 0;
  z-index: 1;
  background: $white-color;
  border-bottom: solid $x $link-color;
}

.gw_quote_offering_name {
  font-weight: $gw-font-weight-bold;
}

.gw_quote_offering_price {
  font-size: $font-xxl;
  font-weight: $gw-font-weight-bold;

  @include media('<=tabletLandscape') {
    font-size: $font-xl;
  }
}

.gw_quote_offering_price_hidden {
  visibility: hidden;
}

.gw_override_alert_styles {
  margin-bottom: 0;
  width: 100%;
}

.gw_offering_uw_warning_icon {
  font-size: $gw-uw-issues-warning-icon-font-size;
  padding-right: $gw-uw-issues-warning-icon-right-padding;
}

.gw_underwriting_issues_warning_text {
  font-size: $gw-uw-issues-warning-text-font-size;
}

.gw_quote_sub_table {
  width: 100%;
}

.gw_quote_table_button {
  width: 100%;
  max-width: 19 * $x;
  min-width: 0;

  @include media('<=tabletLandscape') {
    width: 100%;
  }
}

.gw_quote_table_button_area {
  display: flex;
  justify-content: center;
}

.gw_quote_row_hide {
  position: absolute;
  width: 4 * $x;
  height: 100%;
  background: $gw-hide-icon-gradient;
}

.gw_quote_offering_full_width {
  width: 100%;
}
