@import "base/common-dependencies";

.gwCheckbox {
  margin: $x/2;
}

.gwChoice,
.gwChoiceSelected {
  display: flex;
  padding: $x*4;
  margin-top: $x;
  margin-bottom: $x;
  border-radius: 2px;
  border: 1px solid;
}

.gwChoice {
  border-color: $radioSelectBorderColor;

  &:hover {
    border-color: $wizard-sidebar-steps-current;
  }
}

.gwChoiceSelected {
  background-color: $wizard-sidebar-bg;
  border-color: $wizard-sidebar-bg;
}

.gwDescription {
  font-size: $gw-base-font-size;
}

.gwLabel {
  font-size: $font-xl;
}
