@import "base/common-dependencies";

$gw-choice-padding-top-bottom: 19px !default;
$gw-choice-padding-top-bottom-small: $x/2 !default;
$gw-choice-padding-right-left: 3*$x !default;
$gw-choice-padding-right-left-small: $x !default;
$gw-choice-margin-top: $x !default;
$gw-choice-margin-bottom: $x !default;
$gw-choice-border-radius: 2px !default;
$gw-choice-border: 1px solid !default;
$gw-checkbox-margin: $x/2 !default;
$gw-icon-container-margin: 0 2*$x 0 $x !default;
$gw-icon-font-size: 3*$x !default;
$gw-icon-font-size-small: 2*$x !default;
$gw-search-button-container-margin-top: 2*$x !default;
$gw-search-button-container-padding-bottom: 1.5em !default;
$gw-required-asterisk-left-margin: 5px !default;
$gw-required-asterisk-font-size: 1.2em !default;
$gw-error-message-font-size: 1.4rem !default;
$gw-icon-container-margin-left-small: $x/2 !default;
$gw-icon-container-margin-right-small: $x !default;
$gw-display-name-font-size: 14px !default;
$gw-no-results-text-font-size: 3*$x !default;
$gw-cleat-text-icon-container-font-size: 20px !default;
$gw-no-results-text-margin-top: 42px !default;
$gw-no-results-text-margin-bottom: $x !default;
$gw-no-results-container-margin-bottom: 42px !default;
$gw-no-results-container-padding-bottom: 42px !default;
$gw-no-results-container-border-size: 1px !default;
$gw-conditional-seperator-padding: 0 2px !default;
$gw-address-result-hidden-border: 1px solid $wizard-sidebar-bg !default;
$gw-text-description-right-padding: $x / 4 !default;
$gw-no-search-results-description-font-size: 15px !default;
// The below values are used in the form-validations for ctrl-group inputs.
// We want this search input to be styled the same as the ctrl-group inputs so we need to replicate these values here
$gw-grid-column-count: 12;
$gwControlSection1Width: percentage(4/$gw-grid-column-count) !default;
$gwControlSection2Width: percentage(7.5/$gw-grid-column-count) !default;
$gwControlSectionIndentWidth: percentage(0.5/$gw-grid-column-count) !default;

.gwSearchButtonContainer {
  margin-top: $gw-search-button-container-margin-top;
  display: flex;
  padding-bottom: $gw-search-button-container-padding-bottom;

  .gwManualAddressLink {
    display: inline-block;
  }

  .gwSearchButton {
    margin-left: auto;
  }
}

.gwFindAddressComponent {
  @include media('>phoneLandscape') {
    display: flex;
  }
}

// Start Label Container Styles
.gwLabelContainer {
  @include media('>phoneLandscape') {
    width: $gwControlSection1Width;
  }
}

.gwLabel {
  color: $grey-dark-60-color;
}

.gwRequiredAsterisk {
  color: $red-color;
  margin-left: $gw-required-asterisk-left-margin;
  vertical-align: middle;
  font-size: $gw-required-asterisk-font-size;
}

// End Label Container Styles

.gwClearTextIconContainer {
  font-size: $gw-cleat-text-icon-container-font-size;
  color: $grey-light-50-color;
}

.gwClearTextIcon {
  cursor: pointer;
}

.gwErrorMessage {
  font-size: $gw-error-message-font-size;
  display: inline-block;
  color: $error-text-color;
}

.gwChoice {
  display: flex;
  padding: $gw-choice-padding-top-bottom-small $gw-choice-padding-right-left-small;
  margin-top: $gw-choice-margin-top;
  margin-bottom: $gw-choice-margin-bottom;
  border-radius: $gw-choice-border-radius;
  border: $gw-choice-border;
  border-color: $grey-light-35-color;
  cursor: pointer;

  &:hover {
    border: $gw-address-result-hidden-border;
    background-color: $wizard-sidebar-bg;
  }
}

.gwChoiceSelected {
  border: $gw-address-result-hidden-border;
  background-color: $wizard-sidebar-bg;
}

.gwCheckbox {
  display: flex;
  align-items: center;
  margin: $gw-checkbox-margin;
}

.gwIconContainer {
  display: flex;
  align-items: center;
  margin-left: $gw-icon-container-margin-left-small;
  margin-right: $gw-icon-container-margin-right-small;

  .gwIcon {
    font-size: $gw-icon-font-size-small;
  }
}

.gwDisplayNameContainer {
  display: flex;
  align-items: center;

  .gwDisplayName {
    font-size: $font-s;
  }
}

.gwSearchContainer {
  @include media('>phoneLandscape') {
    margin-left: $gwControlSectionIndentWidth;
    width: $gwControlSection2Width;
  }
}
