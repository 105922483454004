@import "base/common-dependencies";

$gw-offering-coverages-label-padding: 5px !default;
$gw-offering-coverage-item-padding: 20px !default;
$gw-offering-area-border-width: 1px !default;
$gw-coverage-info-button-height-width: 21px !default;
$gw-coverage-info-description-padding: 20px !default;

.gw_offering_coverages {
  display: flex;
  flex-direction: column;
}

.gw_offering_coverages_label {
  background: $grey-light-20-color;
  padding: $gw-offering-coverages-label-padding;
  text-align: center;
}

.gw_coverage_info_description {
  background: linear-gradient(to bottom, $grey-light-20-color, $white-color);
  padding: $gw-coverage-info-description-padding;
  position: relative;
}

.gw_coverage_info_description_button {
  position: absolute;
  right: $gw-coverage-info-description-padding;
}

.gw_coverage_info_button {
  position: absolute;
  cursor: pointer;
  height: $gw-coverage-info-button-height-width;
  width: $gw-coverage-info-button-height-width;
  background: $grey-light-30-color;
  color: $link-color;
  border: none;
  border-top-left-radius: 50%;
  border-bottom-left-radius: 50%;
  font-size: $font-l;
  padding: 0;
}

.gw_coverage_info_button_active {
  background: $link-color;
  color: $white-color;
}

.gw_quote_cell_inner_link {
  color: $link-color;
  cursor: pointer;
  text-align: center;
}

.gw_offering_coverage_item {
  display: flex;
  justify-content: space-between;
  padding: $gw-offering-coverage-item-padding;
  border-top: $grey-light-20-color $gw-offering-area-border-width solid;
  border-bottom: $grey-light-20-color $gw-offering-area-border-width solid;
}

.gw_quote_view_cell {
  width: 100%;
}
