@import "base/common-dependencies";

$gw-facebook-sharing-container-padding: 30px 0 !default;

$gw-facebook-sharing-content-width: 300px !default;
$gw-facebook-sharing-content-padding: 30px !default;
$gw-facebook-sharing-button-padding: 0 16px !default;
$gw-facebook-sharing-text-padding: 0 3px !default;
$gw-facebook-sharing-border-radius: 3px !default;
$gw-facebook-sharing-button-margin-top: 10px !default;

.container {
  display: flex;
  justify-content: center;
  flex-direction: row;
  padding: $gw-facebook-sharing-container-padding;
}

.content {
  @include default-box-border();

  max-width: $gw-facebook-sharing-content-width;
  text-align: center;
  padding: $gw-facebook-sharing-content-padding;

  .button {
    display: inline-flex;
    align-items: center;
    margin-top: $gw-facebook-sharing-button-margin-top;
    font-family: $facebook-font-family;
    font-weight: $gw-font-weight-bold;
    cursor: pointer;
    background-color: $gw-facebook-sharing-background-color;
    padding: $gw-facebook-sharing-button-padding;
    border-radius: $gw-facebook-sharing-border-radius;
  }

  .logo {
    color: $gw-facebook-sharing-logo-color;
  }

  .verb {
    padding: $gw-facebook-sharing-text-padding;
    color: $gw-facebook-sharing-text-color;
  }
}
