@import "base/common-dependencies";

$media-sidebar-width: 300px !default;
$media-sidebar-min-width: 100% !default;
$media-sidebar-max-width: 100% !default;
$media-sidebar-padding: 10px !default;
$media-sidebar-margin-bottom: 20px !default;

$media-sidebar-module-bottom-offset: 20px !default;

.gw-media-sidebar {
  background-color: $media-sidebar-bg;
  width: $media-sidebar-width;
  min-width: $media-sidebar-min-width;
  max-width: $media-sidebar-max-width;
  flex-basis: $media-sidebar-width;
  margin-left: auto;
  margin-bottom: $media-sidebar-margin-bottom;
  display: block;

  .gw-sidebar-content {
    margin: auto;
    display: block;
    padding: $media-sidebar-padding;
    width: $media-sidebar-width;
    min-width: $media-sidebar-min-width;
    max-width: $media-sidebar-max-width;

    & > *:not(:empty) { //Layout for everything inside the sidebar
      display: block;
      overflow: hidden; //Fixes an issue in IE11, @see: AP-1589

      $width: 98%;

      width: $width;
      margin: auto (100 - $width)/2;

      &:not(:last-child) {
        margin-bottom: $media-sidebar-module-bottom-offset;
      }
    }
  }
}
