@import "base/common-dependencies";

$gtp-recommendations-info-container-left-margin: $x * 4 !default;
$gtp-recommendations-title-padding: 1.5em !default;
$gtp-max-image-size: $x * 25 !default;

.gwGtpRecommendationsTitle {
  line-height: $gw-gtp-panel-title-line-height;
  font-size: $font-xxl;
  padding-top: $gtp-recommendations-title-padding;

  @include media('<=phoneLandscape') {
    font-size: $gw-base-font-size;
  }
}

.gwGtpRecommendationsContainer {
  display: flex;
  width: 100%;
  padding-bottom: $x * 4;

  @include media('<=phoneLandscape') {
    flex-direction: column;
    align-items: center;
  }
}

.gwGtpRecommendationsInfoContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: auto;
  margin-bottom: auto;

  @include media('>phoneLandscape') {
    margin-left: $gtp-recommendations-info-container-left-margin;
  }

  @include media('<=phoneLandscape') {
    align-items: center;
  }
}

.gwGtpRecommendationsInfoHeader {
  font-size: $font-xl;
  font-weight: $gw-font-weight-regular;
  padding-bottom: $x;

  @include media('<=phoneLandscape') {
    text-align: center;
  }
}

.gwGtpRecommendationsInfoText {
  padding-bottom: $x * 3;

  @include media('<=phoneLandscape') {
    text-align: center;
    font-size: $gw-base-font-size;
  }
}

.gwGtpRecommendationsProductImage {
  max-width: $gtp-max-image-size;
  max-height: $gtp-max-image-size;
  width: 100%;
}
