@import "base/common-dependencies";

.wrap {
  display: flex;
  flex-direction: row;
}

.textStyle {
  font-size: $gw-base-font-size;
}
