@import "base/common-dependencies";

$gw-offering-header-border-width: 3px !default;
$gw-offering-header-section-padding: 15px !default;
$gw-binary-choice-container-bottom-margin: 15px !default;
$gw-offering-bottom-margin: 10px !default;
$gw-offering-footer-padding: 15px !default;
$gw-offering-bottom-padding: 10px !default;
$gw-uw-issues-warning-icon-left-margin: $x !default;
$gw-uw-issues-warning-icon-font-size: $font-l !default;
$gw-uw-issues-warning-text-font-size: $gw-base-font-size !default;
$gw-coverage-info-button-height-width: 21px !default;
$gw-coverage-info-description-padding: 20px !default;
$gw-offering-name-left-padding: $x !default;
$gw-offering-area-border-width: 1px !default;
$gw-reset-link-div-top-margin: 10px !default;
$gw-reset-link-div-negative-bottom-margin: -10px !default;

.gw_offering_name {
  margin-left: $gw-offering-name-left-padding;
}

.gw_yearly_monthly_price_labels {
  font-size: $gw-base-font-size;
}

.gw_binary_choice_container {
  margin-bottom: $gw-binary-choice-container-bottom-margin;
  text-align: center;
}

.gw_offering_area {
  display: flex;
  flex-direction: column;
}

.gw_offering {
  margin-bottom: $gw-offering-bottom-margin;
  border: $grey-light-20-color $gw-offering-area-border-width solid;
}

.gw_offering_header {
  display: flex;
  border-bottom: $theme-color $gw-offering-header-border-width solid;
}

.gw_offering_header_section {
  width: 50%;
  padding: $gw-offering-header-section-padding;
}

.gw_offering_header_summary_mode {
  background: $grey-light-20-color;
}

.gw_offering_buy_section {
  text-align: center;
}

.gw_buy_now_recalculate_button {
  display: block;
  margin: 0 auto;
}

.gw_reset_link_div {
  margin-top: $gw-reset-link-div-top-margin;
  margin-bottom: $gw-reset-link-div-negative-bottom-margin;
}

.gw_offering_header_accordion_label {
  display: flex;
}

.gw_offering_price {
  font-size: $font-xxl;
}

.gw_offering_footer {
  display: flex;
  justify-content: space-between;
  padding: $gw-offering-footer-padding;
  border-top: $grey-light-60-color $gw-offering-area-border-width solid;
}

.gw_override_alert_margin {
  margin-bottom: 0;
}

.gw_underwriting_issues_warning_text {
  font-size: $gw-uw-issues-warning-text-font-size;
}

.gw_offering_uw_warning_icon {
  font-size: $gw-uw-issues-warning-icon-font-size;
  padding-right: 0;
  margin-left: $gw-uw-issues-warning-icon-left-margin;
}

.gw_price_diff_indicator {
  position: absolute;
  left: calc(50% - #{$gw-price-diff-indicator-large-flavor-width/2});
}
