@import "base/common-dependencies";

$left-column-width: 10% !default;
$jumbotron-margin: 40px 0 24px 0 !default;
$left-column-margin: 0 $x*4.75 0 0 !default;
$jumbotron-section-width: 100% !default;
$jumbotron-section-row-width: 50% !default;
$vertical-line-height: 61px !default;
$vertical-line-width: 1px !default;
$jumbotron-section-row-heading: 20px !default;
$jumbotron-section-row-subheading: 15px !default;
$jumbotron-section-row-button-top-margin: 40px !default;
$gw-default-margin: 20px !default;
$gw-default-margin_percent: 8% !default;
$option-width: 44% !default;
$wizard-section-top-margin: -75px !default;
$seperator-marging: 0 0 24px 0 !default;
$header-row-margin: 10px 0 40px 0 !default;

.gw_spreadsheet_processor {
  display: flex;
  flex-direction: column;

  .seperator {
    margin: $seperator-marging;
  }

  &__row {
    display: flex;
    flex-flow: row nowrap;
    margin: $header-row-margin;
  }

  &__left {
    @include media('<=tabletPortrait', '>phonePortrait') {
      margin: $gw-default-margin $gw-default-margin $gw-default-margin 0;
    }

    &.additional_info {
      @include media('<=tabletPortrait', '>phonePortrait') {
        margin: 0;
      }
    }
  }

  &__right {
    &.additional_info {
      text-align: right;

      @include media('<=tabletPortrait', '>phonePortrait') {
        margin-left: $gw-default-margin_percent;
      }
    }
  }

  &__left,
  &__right {
    flex-basis: $option-width;
  }

  &__middle {
    flex-basis: 100% - (2 * $option-width);
    align-self: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    color: $grey-dark-60-color;
    line-height: 1;

    @include media('<=tabletPortrait') {
      display: none;
    }
  }

  &__jumbotron {
    width: $jumbotron-section-width;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column;
    margin: $jumbotron-margin;

    .section {
      display: flex;
      flex-flow: column;
      width: $jumbotron-section-width;

      .row {
        width: $jumbotron-section-row-width;
        display: flex;
        flex-flow: row;
        align-self: center;

        @include media('<=desktopSmall') {
          width: $jumbotron-section-width;
        }

        .column {
          display: flex;
          align-items: flex-start;
          justify-content: flex-start;
          align-self: flex-start;

          &.left {
            justify-content: center;
            display: flex;
            width: $left-column-width;
            margin: $left-column-margin;

            .vr {
              display: flex;
              width: $vertical-line-width;
              height: $vertical-line-height;
              background-color: $grey-light-30-color;
            }
          }

          &.right {
            width: 90%;
            display: flex;
            flex-flow: column wrap;

            .greyedout {
              color: $grey-dark-60-color;
            }

            .gap {
              margin-bottom: $x*2;
            }

            h3 {
              font-weight: normal;
              font-size: $jumbotron-section-row-heading;
            }

            .paraText {
              font-weight: normal;
              font-size: $jumbotron-section-row-subheading;
            }

            .downloadbutton {
              margin-top: $jumbotron-section-row-button-top-margin;
            }
          }
        }
      }
    }
  }

  &__para {
    margin: 0;
    color: $grey-dark-60-color;

    .breakline {
      display: flex;
    }
  }
}
