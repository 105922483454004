@import "base/common-dependencies";

$gw-footer-mobile-position: absolute !default;
$gw-footer-mobile-bottom: 0 !default;
$gw-footer-mobile-non-link-item-display: block !default;
$gw-footer-mobile-non-link-item-cursor: pointer !default;
$gw-footer-mobile-non-link-item-padding: 10px 17px !default;

.gwFooterMobile {
  @include media('>phoneLandscape') {
    display: none;
  }

  position: $gw-footer-mobile-position;
  bottom: $gw-footer-mobile-bottom;
}

.gwFooterMobileNonLinkItem {
  display: $gw-footer-mobile-non-link-item-display;
  cursor: $gw-footer-mobile-non-link-item-cursor;
  padding: $gw-footer-mobile-non-link-item-padding;
}
