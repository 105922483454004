@import "base/common-dependencies";
@import "../common/tile-variables";
@import "../common/tile-mixins";

// WizardAction Tile Type
.gw-tile-container__wizardActionDefault {
  @include gw-tile-container-default();

  padding: 2 * $x;
  border: 1px solid $active-color;
}

.gw-tile-actions-container__wizardActionDefault {
  margin-top: 3 * $x;
}

.gw-tile-content-container__content:first-child {
  @include gw-tile-content-container__default();
}

// Wizard Action Tile Flavors from here onwards
.gw-tile-container__wizardActionDefaultDisabled {
  @include gw-tile-container-default();

  padding: 2 * $x;
  color: $black-color;
  border: 1px solid $grey-light-40-color;
  width: $gw-tile-wizard-size;
  height: $gw-tile-wizard-size;
}
