@import "base/common-dependencies";

$gwInsetItemsHOffset: $x;
$gwInsetItemsVOffset: $x / 2;
$dashboardSearch__TextInput-Padding: 20px;

gw-text-input {
  display: block;

  @include clearfix();
}

.inputBaseline {
  width: 0;
  height: 0;
  overflow: hidden;

  &__placeholder {
    @include gwInput();
  }
}

.input {
  /*
  Input is inline-block for ease of use.
  It contains input-content — an actual input implementation,
  and left/right blocks for extra content

  Inside input-content there is a placeholder, input controller and input view.

  Placeholder is height: 0 and position: absolute, so has no effect on the layout
  (but has same sizing properties applied as input controller to be positioned in the same exact place).

  Input controller is an actual input element. It's not styled, only there to receive user input.
  Input view is position: absolute and stretched within input-content to overlap the entire element.
  It has styles applied to it — this is where the looks are coming from.
  */
  $viewZIndex: 1;
  $visibleStuffZIndex: $viewZIndex + 1;

  font-size: $gw-base-font-size;
  position: relative;
  display: flex;
  vertical-align: baseline;
  box-sizing: border-box;
  width: 100%;
  float: left;
  cursor: text;
  color: $grey-dark-60-color;

  @include gwInputSizing();

  &__content {
    display: block;
    overflow: hidden;
    height: 100%;
    flex-grow: 1;
  }

  &_gwDashboardSearch {
    padding-right: $dashboardSearch__TextInput-Padding;
    padding-left: $dashboardSearch__TextInput-Padding;
    height: 100%;
  }
  //Input controller and view
  textarea#{&}__controller,
  input#{&}__controller { //Increase that damn specificity
    color: $grey-dark-60-color;
    z-index: $visibleStuffZIndex;
    height: 100%;
    width: 100%;
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    cursor: text;
    vertical-align: baseline;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-appearance: none;

    //Hide the styles of input, it's there as a controller, view is separate
    max-width: 100%;
    min-width: 0;
    max-height: 100%;
    min-height: 0;
    padding: 0;
    margin: 0;
    border: none;
    background: transparent;
    font: inherit;
    box-shadow: none;
    border-radius: 0;
  }

  textarea#{&}__controller {
    resize: none; //Hide the resize handler for textarea

    @include media('>phoneLandscape') {
      vertical-align: middle;
    }
  }

  &__view {
    //Make it fill all the space below the controller
    position: absolute;
    z-index: $viewZIndex;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    -webkit-appearance: none;

    //Pull appearance from the usual input
    @include gwInput();

    height: auto; //Reset height

    &_adjacent {
      &_bottom {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }

  //Placeholder
  &__placeholder {
    position: relative;
    display: block;
    height: 0;
    color: rgba(0, 0, 0, 0.5);
  }

  &__placeholderInner {
    z-index: $visibleStuffZIndex;

    //Position it strictly under the text
    position: absolute;
    left: -1px;
    top: -1px;
    box-sizing: border-box;

    @include gwInputSizing();

    padding: 0; //Remove the padding
    height: auto; //Reset height
    min-height: 0;
    pointer-events: none;
    max-width: 100%;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  //Inset elements
  &__left,
  &__right {
    position: relative;
    z-index: $visibleStuffZIndex;
    margin: $gwInsetItemsVOffset*-1 $gwInsetItemsHOffset*-1 $gwInsetItemsVOffset*-1 $gwInsetItemsHOffset*-1;
    padding: $gwInsetItemsVOffset $gwInsetItemsHOffset $gwInsetItemsVOffset $gwInsetItemsHOffset;
    display: flex;
    align-items: center;
    margin-left: 0;
  }
}
