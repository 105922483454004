@import "base/common-dependencies";

$contents-padding: 0 0 0 $x*4;
$contents-margin: $x 0 0 0;
$location-summary-tooltip-padding: 0 $x;

.gwCPLocationSummary {
  margin: 0;
  font-size: $gw-base-font-size;
  font-weight: normal;

  h2 {
    margin-bottom: $x/4;
    color: $grey-dark-60-color;
    font-size: ($gw-base-font-size/1.2);
    font-weight: normal;
  }

  &_details {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;

    @include media('<=tabletPortrait') {
      flex-direction: column;
    }
  }

  &_info {
    flex: 0 1 33%;
    margin: $contents-margin;
    padding: $contents-padding;
  }

  &_info_accodianLess {
    flex: 1 0 33%;
  }

  &_linksSection {
    flex: 1 0 auto;
    margin: $contents-margin;
    padding: $contents-padding;
  }

  &_phone,
  &_code,
  &_fireProtection,
  &_address,
  &_editLink,
  &__territoryCode {
    padding-bottom: $x*2;
  }

  &_linksIcons {
    color: $link-color;
  }

  &_emptySpace {
    margin-right: $x;
  }

  &__tooltip {
    font-size: $gw-base-font-size;
    color: $link-color;
    padding: $location-summary-tooltip-padding;
  }
}
