@import "base/common-dependencies";

$definition-width: 218px;
$term-color: $grey-dark-70-color;
$definition-width-tablet: 340px;
$gutter: 40px;

gw-definition {
  min-width: $definition-width;
  display: flex;
  flex-direction: column;
  padding-bottom: 3 * $x;
  // calc(50% - $margin / 2);
  flex: 0 0 calc(50% - 40px / 2);
  margin: 2px 0;

  &:nth-child(2n-1) {
    margin-right: $gutter;
  }
}

.term {
  color: $term-color;
  display: block;
}

@include media('<=tabletPortrait') {
  .definition {
    min-width: $definition-width-tablet;
  }
}
