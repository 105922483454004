@import "base/common-dependencies";

$gw-typeahead-popup-nonselected-text: $grey-dark-60-color !default;
$gw-typeahead-active-match-bg: lighten($brand-alt-01, 32%) !default;
$gw-typeahead-active-match-color: $grey-dark-90-color !default;
$gw-typeahead-highlighted-match-color: $black-color !default;
$gw-typeahead-icon-color: $grey-light-40-color !default;
$gw-typeahead-icon-hover-color: $grey-light-50-color !default;

gw-typeahead {
  display: block;
  position: relative;
}

.gwTypeahead {
  &__results {
    display: block;
    top: calc(100% - #{$inputBorder}); //Move so that it overlaps input border
    right: 2 * $inputBorder; //Makes it slightly wider
  }

  &__match {
    cursor: pointer;
    padding: $x $x*2;
    color: $gw-typeahead-popup-nonselected-text;

    &_active {
      background: $gw-typeahead-active-match-bg;
      color: $gw-typeahead-active-match-color;
    }
  }

  &__highlight {
    color: $gw-typeahead-highlighted-match-color;
  }

  &__wipeIcon,
  &__searchIcon {
    color: $gw-typeahead-icon-color;
  }

  &__wipeIcon {
    cursor: pointer;

    &:hover {
      color: $gw-typeahead-icon-hover-color; //Only for clickable icon
    }
  }

  &__loader {
    display: block;
    height: 1px; //Should have some height for text flow purposes, but loader inside is bigger than 1 line, so want it to overflow without affecting height
    font-size: $gw-base-font-size/2;
  }

  &__loaderInner {
    transform: translateY(-50%);
  }

  &__selection {
    textarea {
      white-space: nowrap;
      overflow-x: hidden;
    }
  }
}
