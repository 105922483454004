@import "base/common-dependencies";

$gw-offering-coverage-item-padding: 20px !default;
$gw-summarized-coverage-bottom-padding: 5px !default;
$gw-offering-header-accordion-label-padding: 15px !default;
$gw-offering-header-accordion-label-top-border: 2px !default;

.gw_offering_summary {
  background: $grey-light-20-color;
  padding: $gw-offering-coverage-item-padding;

  input[type="checkbox"] {
    &:checked:disabled + label::before {
      background-color: $grey-light-20-color;
    }
  }
}

.gw_summarized_coverage {
  padding-bottom: $gw-summarized-coverage-bottom-padding;
}

.gw_offering_header_accordion_label {
  display: flex;
  justify-content: space-between;
  padding: $gw-offering-header-accordion-label-padding;
  border-top: $gw-offering-header-accordion-label-top-border $grey-light-30-color solid;
  background: $grey-light-20-color;
}
