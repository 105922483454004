@import "base/common-dependencies";
@import "mixinsAndVars";

.list {
  &__inner {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
  }

  &__items {
    padding-bottom: 10 * $x; //Extra space so that there's an area for dropdown to open
  }

  &__item {
    margin: 0 $gwMobileScheduleItemHOffset;
    padding: $gwMobileScheduleItemVOffset 0;
    border-top: 1px solid $grey-light-30-color;

    &:first-child {
      border-top-color: transparent;
    }
  }

  &__itemContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
  }

  &__dataWrap {
    flex-grow: 1;
  }

  &__itemEdit {
    color: $active-color;
    font-size: $gw-font-md-mobile;
    line-height: 0;
    margin-left: $x;
    cursor: pointer;
  }

  &__itemEditIcon {
    color: $active-color;
    margin-right: $x;
  }

  &__add {
    position: absolute;
    right: $x*2;
    bottom: $x*2;
    opacity: 0;
    transition-property: opacity;
    transition-duration: 0.2s;
    transition-timing-function: linear;
  }

  &:global(.in) &__add {
    opacity: 1;
  }

  &__empty {
    margin-top: 4 * $x;
    margin-left: $gwMobileScheduleItemHOffset;
  }

  &__emptyIcon {
    margin-right: $x;
  }
}
