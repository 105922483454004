@import "base/common-dependencies";
@import "../common/tile-variables";
@import "../common/tile-mixins";

.gw-tile-footer__generic {
  @include gw-tile-default-sections-styles();
}

.gw-tile-header__generic {
  @include gw-tile-default-sections-styles();
}
