@import "base/common-dependencies";

$gw-building-code-spacing: 1.5 * $x !default;

.wrap {
  display: flex;
  flex-direction: row;
}

.left {
  flex-shrink: 1;
}

.right {
  flex-grow: 1;
  margin-left: $gw-building-code-spacing;
}
