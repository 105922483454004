@import "base/common-dependencies";

$table-desktop-padding-horizontal: 10px;
$table-desktop-padding-vertical: 20px;

.gwTableButtonSize {
  width: 100%;
}

.gwTableSize {
  width: inherit;
}

.gwTableRow {
  .gwTableCellPadding {
    padding: $table-desktop-padding-vertical $table-desktop-padding-horizontal;
  }
}

.gwTableAddRow {
  .gwTableCellPadding {
    padding: $table-desktop-padding-vertical 0;
  }
}
