@import "base/common-dependencies";

/* ------------------------------------*\
    DROPDOWN
    .gw-pl-dropdown-menu-wrapper is appended to "body" element to ignore overlow:hidden; etc.
    Structure:
    .gw-pl-dropdown-wrapper[.open] (.gw-pl-dropdown-toggle + .gw-pl-dropdown-menu-wrapper[.open] .dropdown-menu)
\*------------------------------------ */

$gwPlDropdownArrowSize: 7px !default;
$gwPlDropdownArrowRightPos: 1rem !default;

$gwPlDropdownHorPadding: 25px !default;
$gwPlDropdownVerPadding: 10px !default;
$gwPlDropdownYIndent: 10px !default;
$gwPlDropdownItemsVerPadding: 10px !default;

$gwPlCaretFontSize: $font-m !default;
$gwPlCaretSize: $gwPlCaretFontSize/4 !default;

.gw-pl-dropdown-wrapper {
  display: inline-block;
  position: relative;

  .gw-pl-dropdown-toggle {
    cursor: pointer;

    .gw-icon-caret {
      font-size: $gwPlCaretFontSize;
      transition: transform 0.2s;
      margin-left: 3px;
    }
  }

  &.gw-open {
    .gw-icon-caret {
      transform: rotate(180deg);
    }
  }
}

.gw-pl-dropdown-menu-wrapper {
  position: absolute;
  display: none;

  &.gw-open {
    display: block;
  }

  .gw-dropdown-menu {
    border: none;
    border-radius: 0;
    left: auto;
    right: -$gwPlDropdownArrowRightPos;
    transform: translateY($gwPlDropdownYIndent);
    color: $gw-pl-dropdown-color;
    padding: $gwPlDropdownVerPadding $gwPlDropdownHorPadding;
    min-width: 0;
    box-shadow: 0 0 4px 0 rgba($black-color, 0.5);

    // arrow
    &::after {
      @include triangle(top, $gwPlDropdownArrowSize);

      position: absolute;
      right: $gwPlDropdownArrowRightPos - $gwPlCaretSize;
      bottom: 100%;
    }

    &_noarrow::after {
      content: none; //Removes the arrow
    }

    li {
      white-space: nowrap;
      padding: $gwPlDropdownItemsVerPadding 0;

      a,
      .gw-dropdown-menu-item {
        cursor: pointer;
        // reset of pre-defined styles
        color: $gw-pl-dropdown-color;
        display: inline-block;
        background: none;
        padding: 0;
        line-height: inherit;

        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
