@import "base/common-dependencies";

$gw-footer-height: $x * 10 !default;
$gw-footer-padding: 100px !default;
$gw-main-wrapper-padding-bottom: $gw-footer-height + $gw-footer-padding !default;
$gw-footer-position: absolute !default;
$gw-footer-width: 100% !default;
$gw-footer-bottom: 0 !default;
$gw-footer-left-and-right-position: absolute !default;
$gw-footer-left-and-right-bottom: $x * 4 !default;
$gw-footer-left-right: 20px !default;
$gw-footer-item-display: inline !default;
$gw-footer-item-padding: $x/2 $x*3 !default;
$gw-footer-item-right-border: solid 1px $footer-link-border-color !default;

// ANDIE Remove global padding
//:global(.gw-main-wrapper) {
//  @include media('>phoneLandscape') {
//    padding-bottom: $gw-main-wrapper-padding-bottom;
//  }
//}

.gwFooter {
  @include media('<=phoneLandscape') {
    display: none;
  }

  color: $footer-color;
  background-color: $footer-bg;
  position: $gw-footer-position;
  bottom: $gw-footer-bottom;
  height: $gw-footer-height;
  width: $gw-footer-width;
}

.gwFooterLeft,
.gwFooterRight {
  position: $gw-footer-left-and-right-position;
  bottom: $gw-footer-left-and-right-bottom;
}

.gwFooterLeft {
  left: $gw-footer-left-right;
}

.gwFooterRight {
  right: $gw-footer-left-right;
}

.gwFooterItem {
  display: $gw-footer-item-display;
  padding: $gw-footer-item-padding;

  &:not(:last-child) {
    border-right: $gw-footer-item-right-border;
  }

  a {
    color: $footer-color;
  }
}

