@import "base/common-dependencies";
@import "default-styles/common/form-validation-definitions";
@import "mixinsAndVars";

.edit {
  &__titleLine {
    border-bottom: 2px solid $grey-light-50-color;
    margin: $x*2 0 $x*3;
  }

  &__inner {
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: auto;
    padding: $gwMobileScheduleItemVOffset $gwMobileScheduleItemHOffset;
  }

  &__buttonsLine {
    margin-top: calc(#{3*$x} - #{$controlGroupBottomIndent});
    border-top: 1px solid $grey-light-30-color;
    margin-bottom: $x*3;
  }

  &__buttons {
    @include buttonGroup();
  }
}
