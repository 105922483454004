@import "base/common-dependencies";

$gw-mobile-schedule-accent-color: $link-color !default;
$gw-mobile-schedule-heading-font-size: 20px !default;

.clickarea {
  cursor: pointer;
  background-color: $grey-light-10-color;
  display: flex;
  flex-direction: row;
  align-items: center;
  align-content: center;
  padding: $x*2;
  border-radius: 2px;

  &__content {
    flex-grow: 1;
  }

  &__header {
    font-size: $gw-mobile-schedule-heading-font-size;
    font-weight: $gw-font-weight-regular;
    line-height: $base-line-height;
    color: $gw-mobile-schedule-accent-color;
  }

  &__chevron {
    color: $gw-mobile-schedule-accent-color;
    line-height: 0;
    margin-left: $x;
  }
}

.memo {
  color: $grey-dark-60-color;
  margin: $x 0;
  font-size: $gw-font-xs;
}
