@import "base/common-dependencies";
@import "components/widgets/common/action-icon-vars";

.wizardStepCircles {
  display: flex;
  flex-flow: row nowrap;

  &__baseline { //Establishes bottom of the circles as the baseline for the element
    width: 0;
    overflow: hidden;

    $line-height: 1px;

    font-size: $line-height;
    line-height: $line-height;
    margin-top: calc(#{$gw-action-icon-outer-size-small} - #{2 * $line-height});
  }

  &__step {
    flex-grow: 0;
    flex-shrink: 1;
    display: flex;
    flex-flow: row nowrap;
    align-items: baseline;
  }

  &__stepContent {
    display: flex;
    flex-direction: column;
    align-items: center;

    &_hoverable {
      cursor: pointer;
    }
  }

  &__stepSeparator {
    margin: 0 $x*2;
    width: $x*2;
    border-bottom: 1px solid $grey-light-30-color;
    color: rgba(0, 0, 0, 0);
    line-height: 0.01;
  }

  &__stepIcon {
    flex: 0 1 auto;
  }

  &__stepLabel {
    flex: 0 1 auto;
    margin-top: $x;
    font-size: $gw-font-xs;
    color: $grey-light-60-color;

    &_active {
      color: $black-color;
    }
  }
}
