@import "base/common-dependencies";
@import "../../common/tile-variables";
@import "../../common/tile-mixins";

.gwWizardTileLabelDefault {
  color: $black-color;
  height: 2 * $gw-tile-label-font-size;
  font-size: $gw-tile-label-font-size;
  line-height: $gw-tile-label-font-size;
  text-align: center;
  overflow: inherit;
}

.gwWizardTileLabelSmall {
  display: none;
}

.gwWizardTileLabelActive {
  color: $white-color;
}
