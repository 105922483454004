@import "base/common-dependencies";

.gw-cp-initial-screen {
  &__row {
    display: flex;
    flex-flow: row nowrap;
  }

  &__wholeRow {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    border: 1px dashed $grey-dark-60-color;
    padding: $x*5 0;
    margin-bottom: -3*$x;

    .header-info {
      margin-bottom: $x*2;
    }
  }

  $gw-default-margin: 20px;

  &__left {
    @include media('<=tabletPortrait', '>phonePortrait') {
      margin: $gw-default-margin $gw-default-margin $gw-default-margin 0;
    }

    &.additional-info {
      @include media('<=tabletPortrait', '>phonePortrait') {
        margin: 0;
      }
    }
  }

  &__right {
    &.additional-info {
      $gw-default-margin_percent: 8%;

      @include media('<=tabletPortrait', '>phonePortrait') {
        margin-left: $gw-default-margin_percent;
      }
    }
  }

  $option-width: 44%;

  &__left,
  &__right {
    flex-basis: $option-width;
  }

  &__middle {
    flex-basis: 100% - (2 * $option-width);
    align-self: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    color: $grey-dark-60-color;
    line-height: 1;

    @include media('<=tabletPortrait') {
      display: none;
    }
  }

  $middle-line-width: 16px;

  &__middle-line {
    display: inline-block;
    margin: $x;
    width: $middle-line-width;
    min-width: $x*2;
    border-bottom: 1px solid $grey-light-30-color;
    font-size: 1px;
    vertical-align: middle;
  }

  &__para {
    margin: $x 0 $x*2;
    color: $grey-dark-60-color;
  }
}
