@import "base/common-dependencies";

$gw-cp-buildings-list-navigation-spacing: 8px;
$gw-cp-buildings-list-navigation-top-spacing: 0;
$gw-cp-buildings-list-divider-desktop-spacing: 10px;
$gw-cp-buildings-list-divider-mobile-spacing: 0;
$gw-cp-buildings-list-ellipsis-padding: 0 0.5 * $x;
$gw-cp-buildings-list-ellipsis-margin: -73px 15px 35px 0;
$gw-cp-buildings-list-ellipsis-margin-right: 15%;
$gw-cp-buildings-list-header-action-bar-buttons-margin-right: 10px;
$gw-cp-buildings-list-new-badge-right-margin-action-bar-buttons-margin-right: 10px;
$gw-cp-buildings-list-title-bottom-spacing: 40px;

.gw-cp-buildings-list {
  &__page-title {
    margin-bottom: $gw-cp-buildings-list-title-bottom-spacing;
  }

  &__header-action-bar-buttons {
    margin-right: $gw-cp-buildings-list-header-action-bar-buttons-margin-right;
  }

  &__ellipsis-container {
    @include media('>tabletPortrait') {
      float: right;
      margin-right: $gw-cp-buildings-list-ellipsis-margin-right;
    }

    @include media('<=tabletPortrait') {
      display: flex;
      flex: 1 0 auto;
      justify-content: flex-end;
    }
  }

  &__ellipsis {
    color: $active-color;
    font-size: $gw-font-md-mobile;
    padding: $gw-cp-buildings-list-ellipsis-padding;

    @include media('<=tabletPortrait') {
      visibility: visible;
      margin: $gw-cp-buildings-list-ellipsis-margin;
      float: left;
    }

    &:hover {
      color: $btn-primary-hover-bg;
    }
  }

  &__navigation {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @include media('<=tabletPortrait') {
      flex-direction: column;
      align-items: flex-end;
    }

    &_top {
      padding: $gw-cp-buildings-list-navigation-top-spacing 0 $gw-cp-buildings-list-navigation-spacing*2 0;

      @include media('>tabletPortrait') {
        padding-bottom: $gw-cp-buildings-list-navigation-spacing*3;
      }
    }

    &_bottom {
      padding: $gw-cp-buildings-list-navigation-spacing*2 0;

      @include media('>tabletPortrait') {
        padding: $gw-cp-buildings-list-navigation-spacing*3 0;
      }
    }

    &_reverse {
      flex-direction: row-reverse;
    }
  }

  &__search {
    width: 40%;

    @include media('<=tabletPortrait') {
      width: 100%;
      padding-bottom: $gw-cp-buildings-list-navigation-spacing;
    }
  }

  &__pagination {
    width: 40%;
    display: flex;
    justify-content: flex-end;

    @include media('<=tabletPortrait') {
      width: 100%;
      padding-top: $gw-cp-buildings-list-navigation-spacing;
    }
  }

  &__horizontal-divider {
    height: 0;
    border: 0;
    border-top: 1px solid $grey-light-30-color;
    margin: 0 0 $gw-cp-buildings-list-divider-desktop-spacing 0;

    @include media('<=tabletPortrait') {
      margin-bottom: $gw-cp-buildings-list-divider-mobile-spacing;
    }
  }

  &__new-badge {
    margin-right: -35px;
  }
}
