@import "base/common-dependencies";

gw-pc-readonly-coverages {
  display: block;
  width: 100%;
}

.gwCoverageEditBtnBlock {
  padding: $x*2 0 $x*3 0;
}

.gwCoverage {
  display: flex;
  margin-bottom: $x*2;
  border-bottom: 1px solid $grey-light-30-color;

  &_last {
    border-bottom: none;
    margin-bottom: 0;
  }

  @include media('<=phoneLandscape') {
    display: inherit;
    margin-bottom: 0;
    border-bottom: none;
  }

  &__name {
    padding-bottom: $x*2;

    @include media('<=phoneLandscape') {
      padding-top: $x*2;
    }

    @include media('>phoneLandscape') {
      width: 1/3 * 100%;
    }
  }

  &__terms {
    @include media('>phoneLandscape') {
      width: 2/3 * 100%;
      padding-left: 3 * $x;
    }
  }

  &__term {
    display: flex;

    @include media('<=phoneLandscape') {
      display: inherit;
    }

    padding-left: $x*2;
  }

  &__termName {
    color: $grey-dark-60-color;
    width: 50%;
    text-align: right;
    padding-right: $x*6;
    padding-bottom: $x*2;

    @include media('<=phoneLandscape') {
      width: 100%;
      padding-right: 0;
      padding-bottom: 0;
      text-align: left;
    }
  }

  &__termAmount {
    padding-bottom: $x*2;
  }
}
