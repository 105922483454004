@import "base/common-dependencies";

$gtp-question-panel-right-padding: $x*5 !default;
$gtp-info-icon-right-margin: $x*4 !default;
$gtp-question-answer-height: $x * 5 !default;

.gwGtpQuestionsTitle {
  line-height: $gw-gtp-panel-title-line-height;
}

.gwGtpQuestionsPanel {
  @include media('>phoneLandscape') {
    padding-right: $gtp-question-panel-right-padding;
  }
}

.gwGtpOptionBox {
  height: 10 * $x;
  width: 10 * $x;
  border-radius: $x;
  border: 1px solid $black-color;
  text-align: center;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
}

.gwGtpInfoIcon {
  margin-right: $gtp-info-icon-right-margin;
}

.gwGtpSelectedBox {
  position: absolute;
  right: 10%;
  bottom: 10%;
}

.gwGtpQuestionIcon {
  font-size: $font-xl;
}

.gwGtpQuestionReadonly {
  display: flex;
  margin-left: $x;

  @include media('>phoneLandscape') {
    margin-left: 0;
  }
}

.gwGtpContinueButton {
  margin-bottom: $gw-gtp-continue-button-bottom-margin;
}

.gwGtpQuestionSelectionContainer {
  display: flex;
  flex-wrap: wrap;
}

.gwGtpQuestionSelection {
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
  height: $gtp-question-answer-height;
  width: $gtp-question-answer-height;
  font-size: $font-xl;
  background: $grey-light-30-color;
}

.gwGtpQuestionSelectionWrapper {
  margin: $x;
}
