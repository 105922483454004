@import "base/common-dependencies";
@import "../common/tile-variables";
@import "../common/tile-mixins";

// Content Tile Type
.gw-tile-container__contentDefault {
  @include gw-tile-container-default();

  border: 1px solid $grey-light-40-color;
}

// Content Tile Flavors
.gw-tile-container__contentSummaryInfo {
  @include gw-tile-container-default();

  border: 1px solid $grey-light-30-color;

  @include media('<=phoneLandscape') {
    flex: 0 0 100%;
    border-right: 0;
    border-left: 0;
    border-top: 0;
  }

  @include media('>phoneLandscape', '<=tabletPortrait') {
    margin: $gw-tile-container-content-margin;
  }
}

.gw-tile-content-container__content:first-child {
  @include gw-tile-content-container__default();
}

.gw-tile-content-container__contentSummaryInfo {
  @include gw-tile-content-container__default();

  width: $gw-content-tile-details-width;
  padding: $gw-content-tile-details-padding;
}

.gw-tile-header__contentSummaryInfo {
  font-size: $gw-content-tile-header-font-size;
  margin: $gw-content-tile-header-margin;
  text-align: center;

  @include media('<=phoneLandscape') {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
}

.gw-tile-actions-container__contentSummaryInfo {
  padding-bottom: $x;
}

.gw-tile-container__contentQuickLinks {
  @include gw-tile-container-default();

  border: 1px solid $grey-light-40-color;

  @include media('<=phoneLandscape') {
    border-right: 0;
    border-left: 0;
    border-top: 0;
  }

  @include media('>phoneLandscape', '<=tabletPortrait') {
    margin: $gw-tile-container-content-margin;
  }
}

.gw-tile-content-container__contentQuickLinks {
  @include gw-tile-content-container__default();

  display: flex;
  flex-direction: column;
  padding: 0 $x*4 $x*2 0;

  @include media('<=phoneLandscape') {
    padding: 0;
  }
}

.gw-tile-actions-container__contentQuickLinks {
  padding-bottom: 0;
}

.gw-tile-header__contentQuickLinks {
  font-size: $gw-content-tile-header-font-size;
  margin: $gw-content-tile-header-margin;
  text-align: center;

  @include media('<=phoneLandscape') {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
  }
}
