@import "base/common-dependencies";

$gw-coverage-to-coverage-v-offset: 4 * $x !default;
$gw-coverage-heading-to-terms-v-offset: 2 * $x !default;

.gwCoverages {
  &__coverage {
    padding-top: $gw-coverage-to-coverage-v-offset;

    &_first {
      padding-top: 0;
    }
  }

  &__coverageHeading {
    padding-bottom: $gw-coverage-heading-to-terms-v-offset;
  }
}
