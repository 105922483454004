@import "base/common-dependencies";

$gw-premises-summary-heading-font-color: #404040 !default;
$gw-premises-summary-heading-vertical-height: 22px !default;
$gw-premises-summary-heading-text-size: $x*3 !default;
$gw-premises-summary-panel-size-margin: 2px 0;
$gw-premises-summary-header-margin: $x*5 0 $x*2 0;
$gw-premises-summary-header-icon-size: 20px !default;
$gw-premises-summary-header-table-icon-size: 15px !default;
$gw-premises-summary-header-table-colum-window-size: $x*2 0 $x*2 $x*4 !default;
$gw-premises-summary-header-button-text-size: 20px !default;

.gwLocationSummary {
  &__panel {
    margin: $gw-premises-summary-panel-size-margin;
  }

  &__header {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: $gw-premises-summary-header-margin;
    cursor: pointer;
    min-height: $gw-premises-summary-heading-vertical-height;
    margin-bottom: $x;

    &__typeIcon {
      font-size: $gw-premises-summary-header-icon-size;
      position: inherit;
      margin: 0;
      width: $x*4;
      color: $gw-premises-summary-heading-font-color;
    }

    &__action {
      flex: 1 0 auto;
      padding: 0 0 0 1.5*$x;
      margin: 0 0 0 $x;
      font-size: $gw-premises-summary-header-button-text-size;
      font-weight: normal;
      text-align: right;
    }

    &__table {
      &_headers {
        padding: $x !important;

        &_icons {
          font-size: $gw-premises-summary-header-table-icon-size;
          padding-right: $x;
        }
      }
    }
  }

  &__contents {
    padding: 0 $x*3;
  }
}
