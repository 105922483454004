@import "base/common-dependencies";

/* *
  Stepper component
*/
$stepper-button-size: $x*5 !default;
$stepper-value-full-width: 10*$x !default;
$stepper-value-padding-hor: $x !default;
$stepper-btn-border-radius: 2px !default;
$stepper-btn-box-shadow: 0 1px 2px 0 rgba($black-color, 0.15) !default;
$stepper-icon-height-width: $x*5;
$stepper-input-container-horizontal-margin: $x;

.gwStepper {
  display: inline-flex;
}

.gwStepperInput {
  display: flex;
  justify-content: space-between;
}

.gwStepperContainer {
  text-align: center;
  line-height: $stepper-button-size;
  font-size: $gw-base-font-size;
  color: $stepper-color;
  align-items: center;

  .gwStepperBtn {
    font-size: $gw-base-font-size;
    width: $stepper-button-size;
    height: $stepper-button-size;
    border-radius: $stepper-btn-border-radius;
    cursor: pointer;
    user-select: none;
    color: $stepper-btn-color;
    border: 1px solid $stepper-btn-border-color;
    box-shadow: $stepper-btn-box-shadow;

    .iconSize {
      width: $stepper-icon-height-width;
      height: $stepper-icon-height-width;
    }

    &.gwDisabled {
      cursor: default;

      &,
      &:hover {
        color: $btn-bg;
        border-color: $stepper-btn-border-color-disabled;
        background-color: $grey-light-color;
        box-shadow: none;
      }
    }

    &:hover {
      color: $btn-hover-color;
      background-color: $btn-hover-bg;
    }
  }

  .gwValue {
    & > * {
      width: $stepper-value-full-width;
      padding: 0 $stepper-value-padding-hor;
      color: $grey-dark-90-color;
      cursor: default;
    }
  }

  .inputContainer {
    margin: 0 $stepper-input-container-horizontal-margin;
    width: 100%;
  }
}
