@import "base/common-dependencies";

$margin-header-bottom: 10px;

.gw-building-wizard-wrapper {
  position: relative;

  &__heading {
    display: flex;
    align-items: baseline;

    @include media('<=tabletPortrait') {
      flex-direction: column;
    }
  }

  &__heading-item {
    &:first-child {
      flex-grow: 1;

      @include media('<=tabletPortrait') {
        margin-bottom: $margin-header-bottom;
      }
    }
  }

  &__view {
    margin-top: $x*2;
  }
}
