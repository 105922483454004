@import "base/common-dependencies";

$form-content-padding: $x*3 0 $x*2;
$form-content-margin-bottom: $x*4;

.gw-cp-add-building-location {
  &__FormContent {
    padding: $form-content-padding;
    border-top: 1px solid $grey-light-30-color;
    margin-bottom: $form-content-margin-bottom;
  }
}
