@import "base/common-dependencies";
@import "../../common/tile-variables";
@import "../../common/tile-mixins";

// Wizard Tile
.gwTileHeaderText {
  margin: $gw-nav-title-top-margin;
  font-size: $gw-nav-tile-title-size;
  font-weight: bold;
}
