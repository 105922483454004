@import "Location-vars";

.location {
  display: flex;
  flex-direction: row;
  align-items: flex-start;

  &__icon {
    font-size: $gw-location-dataitem-icon-font-size;
    margin-right: $gw-location-dataitem-icon-right-offset;
    margin-top: $gw-location-dataitem-icon-top-correction;
    width: $gw-location-dataitem-icon-width;
  }

  &__address {
    flex-grow: 1;
  }
}
