@import "base/common-dependencies";

.menu {
  &__item {
    padding: 2 * $x;
    display: flex;
    flex-wrap: nowrap;
    align-items: baseline;
    cursor: pointer;
    white-space: nowrap;

    &:hover {
      background-color: $grey-light-20-color;
      color: $grey-dark-90-color;
    }
  }

  &__itemIcon {
    color: $active-color;
    margin-right: $x;
  }

  &__item:hover &__itemIcon {
    color: $btn-primary-hover-bg;
  }
}
