@import "base/common-dependencies";
@import "../../common/tile-variables";
@import "../../common/tile-mixins";

// Wizard Tile
.gwTileHeaderIcon {
  margin: $gw-nav-icon-bottom-margin;
  font-size: $gw-nav-tile-title-size;
  font-weight: normal;
}
