@import "base/common-dependencies";

$and-search-button-container-margin-top: 2*$x !default;
$and-search-button-container-padding-bottom: 1.5em !default;
$and-error-message-font-size: 1.4rem !default;

.andSearchButtonContainer {
  margin-top: $and-search-button-container-margin-top;
  display: inline-block;
  padding-bottom: $and-search-button-container-padding-bottom;

  .andManualAddressLink {
    display: inline-block;
  }

  .andSearchButton {
    margin-left: auto;
  }
}

// End Label Container Styles

.andErrorMessage {
  font-size: $and-error-message-font-size;
  display: inline-block;
  color: $error-text-color;
}

