@import "base/common-dependencies";

//Error styles
$gw-schedule-table-control-error-font-size: $control-group-error-font-size !default;
$gw-schedule-table-control-error-font-weight: $control-group-error-font-weight !default;
$gw-schedule-table-control-error-color: $error-text-color !default;

.edit {
  &__error {
    color: $gw-schedule-table-control-error-color;
    font-size: $gw-schedule-table-control-error-font-size;
    font-weight: $gw-schedule-table-control-error-font-weight;
  }
}
