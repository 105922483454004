@import "base/common-dependencies";

.definitionList {
  display: flex;
  flex-flow: row wrap;
  justify-content: flex-start;
  margin-bottom: 4 * $x;
}

.hasTitle {
  padding-left: 2 * $x;
}

.title {
  font-weight: bold;
  padding-bottom: 2 * $x;
  color: $black-color;
}
