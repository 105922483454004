@import "base/common-dependencies";

$gw-input-nested-button-min-height: 3 * $x;
$gw-input-nested-button-font-size: $font-s;
$gw-input-nested-button-padding: 0 2 * $x;
$gw-input-nested-button-min-width: 0;
$gw-input-nested-button-right: 4 * $x;

.gwInputNestedButtonContainer {
  position: relative;
  display: flex;
  align-items: center;
}

.gwInputNestedButtonTransclude {
  width: 100%;
}

.gwInputNestedButton {
  min-height: $gw-input-nested-button-min-height;
  font-size: $gw-input-nested-button-font-size;
  padding: $gw-input-nested-button-padding;
  min-width: $gw-input-nested-button-min-width;
  right: $gw-input-nested-button-right;
  position: absolute;
  bottom: $x;
}
