@import "base/common-dependencies";

.gwTransactionConfirmation {
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;

  &__item {
    align-self: center;
    text-align: center;

    &_bold {
      font-weight: bold;
      margin-bottom: $x * 2;
    }
  }

  &__success {
    font-size: 80px;
    color: lighten($active-color, 40%);
    margin-bottom: $x * 4;

    &_check {
      color: $active-color;
    }
  }

  &__message {
    font-size: $gw-font-lg;
    font-weight: $gw-font-weight-light;
  }

  &__buttons {
    margin-top: $x * 6;

    :first-child {
      margin-right: $x;
    }

    @include media('<=phonePortrait') {
      :first-child {
        margin-bottom: $x;
      }
    }
  }
}
