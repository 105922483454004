@import "base/common-dependencies";

$gwMobileReadonlyScheduleItemVOffset: $x*3 !default;
$gwMobileReadonlyScheduleDataItemVOffset: $x*2 !default;

.location {
  &__item {
    padding: $gwMobileReadonlyScheduleItemVOffset 0;
    border-top: 1px solid $grey-light-30-color;

    &:first-child {
      border-top-color: transparent;
    }
  }

  &__itemContent {
    display: flex;
    flex-direction: row;
    align-items: center;
    align-content: center;
  }

  &__dataWrap {
    flex-grow: 1;
  }

  &__data {
    margin: $gwMobileReadonlyScheduleDataItemVOffset 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__dataLabel {
    color: $grey-dark-60-color;
    font-size: $gw-font-xs;
  }
}
