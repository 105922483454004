@import "base/common-dependencies";
@import "../../common/tile-variables";
@import "../../common/tile-mixins";

.gwTileContentInside {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.gwTileContentItem {
  @include gw-tile-default-sections-styles();
}
