@import "base/common-dependencies";

.gw_quote_cell_area {
  display: flex;
}

.gw_quote_cell_label {
  font-weight: $gw-font-weight-bold;
}

.gw_quote_cell_input_select {
  height: 100%;
}

.gw_quote_cell_input_label {
  color: $grey-dark-60-color;
  padding-top: $x * 1.5;
}

.gw_quote_cell_input_area {
  width: 100%;
}

.gw_quote_cell_term_area {
  display: flex;
  width: 100%;
  flex-direction: column;
}
