@import "base/common-dependencies";

$edit-location-alert-title: $x*2 !default;
$edit-location-form-content-padding: $x*3 0 $x*2 !default;
$edit-location-form-content-padding-bottom: $x*2 !default;
$edit-location-form-content-margin-bottom: $x*4 !default;

.cp-edit-location {
  &__AlertTitle {
    padding-bottom: $edit-location-alert-title;
  }

  &__FormContent {
    border-bottom: 1px solid $grey-light-30-color;
    padding-bottom: $edit-location-form-content-padding-bottom;
    margin-bottom: $edit-location-form-content-margin-bottom;
  }
}
