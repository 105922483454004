@import "base/common-dependencies";

$gw-tooltip-border-radius: 3px !default;
$gw-tooltip-padding: $x !default;
$gw-tooltip-arrow-size: $x !default;

/* ------------------------------------*\
   GUIDEWIRE CUSTOM
\*------------------------------------ */
.tooltipster-content {
  font-size: $gw-font-xs;
  line-height: $base-line-height;

  a {
    color: $gw-tooltip-link-color;
    border-bottom: 1px solid $gw-tooltip-link-hover-color;
    text-decoration: none;

    &:hover {
      color: $gw-tooltip-link-hover-color;
      border-bottom: none;
    }
  }
}

/* ------------------------------------*\
   TOOLTIPSTER
\*------------------------------------ */

/* This is the core styles of Tooltipster */

/* GENERAL STRUCTURE RULES (do not edit this section) */

.tooltipster-base {
  /* this ensures that a constrained height set by functionPosition,
  if greater that the natural height of the tooltip, will be enforced
  in browsers that support display:flex */
  display: flex;
  pointer-events: none;

  /* this may be overriden in JS for fixed position origins */
  position: absolute;
}

.tooltipster-box {
  /* see .tooltipster-base. flex-shrink 1 is only necessary for IE10-
  and flex-basis auto for IE11- (at least) */
  flex: 1 1 auto;
}

.tooltipster-content {
  /* prevents an overflow if the user adds padding to the div */
  box-sizing: border-box;

  /* these make sure we'll be able to detect any overflow */
  max-height: 100%;
  max-width: 100%;
  overflow: auto;
}

.tooltipster-ruler {
  /* these let us test the size of the tooltip without overflowing the window */
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: fixed;
  right: 0;
  top: 0;
  visibility: hidden;
}

/* ANIMATIONS */

/* Open/close animations */

/* fade */

.tooltipster-fade {
  opacity: 0;
  transition-property: opacity;
}

.tooltipster-fade.tooltipster-show {
  opacity: 1;
}

/* Update animations */

/* We use animations rather than transitions here because
 transition durations may be specified in the style tag due to
 animationDuration, and we try to avoid collisions and the use
 of !important */

/* fade */

@keyframes tooltipster-fading {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.tooltipster-update-fade {
  animation: tooltipster-fading 400ms;
}

/* *
 * DEFAULT STYLE OF THE SIDETIP PLUGIN
 *
 * All styles are "namespaced" with .tooltipster-sidetip to prevent
 * conflicts between plugins.
 */

/* .tooltipster-box */

.tooltipster-sidetip .tooltipster-box {
  background: $gw-tooltip-bg;
  border-radius: $gw-tooltip-border-radius;
}

.tooltipster-sidetip.tooltipster-bottom .tooltipster-box {
  margin-top: $gw-tooltip-arrow-size;
}

.tooltipster-sidetip.tooltipster-left .tooltipster-box {
  margin-right: $gw-tooltip-arrow-size;
}

.tooltipster-sidetip.tooltipster-right .tooltipster-box {
  margin-left: $gw-tooltip-arrow-size;
}

.tooltipster-sidetip.tooltipster-top .tooltipster-box {
  margin-bottom: $gw-tooltip-arrow-size;
}

/* .tooltipster-content */

.tooltipster-sidetip .tooltipster-content {
  color: $gw-tooltip-color;
  padding: $gw-tooltip-padding;
}

/* .tooltipster-arrow : will keep only the zone of .tooltipster-arrow-uncropped that
corresponds to the arrow we want to display */

.tooltipster-sidetip .tooltipster-arrow {
  overflow: hidden;
  position: absolute;
}

.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow {
  height: $gw-tooltip-arrow-size;

  /* half the width, for centering */
  margin-left: -$gw-tooltip-arrow-size;
  top: 0;
  width: 2*$gw-tooltip-arrow-size;
}

.tooltipster-sidetip.tooltipster-left .tooltipster-arrow {
  height: 2*$gw-tooltip-arrow-size;
  margin-top: -$gw-tooltip-arrow-size;
  right: 0;

  /* top 0 to keep the arrow from overflowing .tooltipster-base when it has not
  been positioned yet */
  top: 0;
  width: $gw-tooltip-arrow-size;
}

.tooltipster-sidetip.tooltipster-right .tooltipster-arrow {
  height: 2*$gw-tooltip-arrow-size;
  margin-top: -$gw-tooltip-arrow-size;
  left: 0;

  /* same as .tooltipster-left .tooltipster-arrow */
  top: 0;
  width: $gw-tooltip-arrow-size;
}

.tooltipster-sidetip.tooltipster-top .tooltipster-arrow {
  bottom: 0;
  height: $gw-tooltip-arrow-size;
  margin-left: -$gw-tooltip-arrow-size;
  width: 2*$gw-tooltip-arrow-size;
}

/* common rules between .tooltipster-arrow-background and .tooltipster-arrow-border */

.tooltipster-sidetip .tooltipster-arrow-background,
.tooltipster-sidetip .tooltipster-arrow-border {
  height: 0;
  position: absolute;
  width: 0;
}

/* .tooltipster-arrow-background */

.tooltipster-sidetip .tooltipster-arrow-background {
  // arrow is fully build with CSS borders
  display: none;
}

/* .tooltipster-arrow-border */

.tooltipster-sidetip .tooltipster-arrow-border {
  border: $gw-tooltip-arrow-size solid transparent;
  left: 0;
  top: 0;
}

.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow-border {
  border-bottom-color: $black-color;
}

.tooltipster-sidetip.tooltipster-left .tooltipster-arrow-border {
  border-left-color: $black-color;
}

.tooltipster-sidetip.tooltipster-right .tooltipster-arrow-border {
  border-right-color: $black-color;
}

.tooltipster-sidetip.tooltipster-top .tooltipster-arrow-border {
  border-top-color: $black-color;
}

/* tooltipster-arrow-uncropped */

.tooltipster-sidetip .tooltipster-arrow-uncropped {
  position: relative;
}

.tooltipster-sidetip.tooltipster-bottom .tooltipster-arrow-uncropped {
  top: -$gw-tooltip-arrow-size;
}

.tooltipster-sidetip.tooltipster-right .tooltipster-arrow-uncropped {
  left: -$gw-tooltip-arrow-size;
}

gw-tooltip > opener {
  display: inline-block;
}
