@import "base/common-dependencies";

$gw-badge-font-size: $gw-font-xxs !default;
$gw-price-diff-indicator-height: 30px !default;
$gw-price-diff-indicator-width: $gw-price-diff-indicator-large-flavor-width !default;
$gw-indicator-animation-delay-seconds: 3s !default;

//0-100 lightness threshold.
//On backgrounds lighter than that dark text color is used,
//on backgrounds darker than that light text color is used.
$gw-badge-lightness-threshold: 59 !default;

//Badge colors map with name-color pairs
/* stylelint-disable */
$gw-badge-types-backgrounds: (
  default: $alert-warning-color,
  gateway-note: $note-category-background-color,
  cpbuildinglistview-new-building: $gw-badge-cpbuildinglistview-new-building-color,
  accountcontact-status-inactive: $gw-badge-accountcontact-inactive-color,
  policyperiod-status-New: $gw-badge-policystatus-ok-color,
  policyperiod-status-Draft: $gw-badge-policystatus-ok-color,
  policyperiod-status-Quoted: $gw-badge-policystatus-ok-color,
  policyperiod-status-Bound: $gw-badge-policystatus-ok-color,
  policyperiod-status-Withdrawn: $gw-badge-policystatus-important-color,
  policyperiod-status-Declined: $gw-badge-policystatus-important-color,
  policyperiod-status-Expired: $gw-badge-policystatus-important-color,
  policyperiod-status-NonRenewed: $gw-badge-policystatus-important-color,
  policyperiod-status-NotTaken: $gw-badge-policystatus-important-color,
  policyperiod-status-NonRenewing: $gw-badge-policystatus-important-color,
  policyperiod-status-NotTaking: $gw-badge-policystatus-important-color,
  policyperiod-status-Canceling: $gw-badge-policystatus-important-color,
  policyperiod-status-Rescinding: $gw-badge-policystatus-important-color,
  policyperiod-status-Rescinded: $gw-badge-policystatus-important-color,
  policyperiod-status-Reinstating: $gw-badge-policystatus-important-color,
  policyperiod-status-Waived: $gw-badge-policystatus-important-color,
  policyperiod-status-Quoting: $gw-badge-policystatus-important-color,
  policyperiod-status-Binding: $gw-badge-policystatus-important-color,
  policyperiod-status-Renewing: $gw-badge-policystatus-important-color,
  policyperiod-status-Temporary: $gw-badge-policystatus-ok-color,
  policyperiod-status-LegacyConversion: $gw-badge-policystatus-ok-color,
  activity-priority-low: $gw-badge-activity-priority-low-color,
  activity-priority-normal: $gw-badge-activity-priority-normal-color,
  activity-priority-high: $gw-badge-activity-priority-high-color,
  activity-priority-urgent: $gw-badge-activity-priority-urgent-color,
  price_diff_indicator: $gw-gtc-price-difference-indicator-background-color,
) !default;
/* stylelint-enable */

//Text colors, to allow overriding computed value based on lightness
$gw-badge-types-text-color: (accountcontact-status-inactive: white, cpbuildinglistview-new-building: $grey-dark-90-color) !default;

.gwBadge {
  display: flex;
  align-items: center;
  align-content: center;
  flex: 1 1 1px;

  &__content {
    flex: 0 1 auto;
    font-size: $gw-badge-font-size;
    line-height: 1;
    padding: 4px 10px;
    border-radius: $gw-badge-font-size;
  }

  /* stylelint-disable */
  @function gw-badge-select-text-color($background) {
    @if (lightness($background) > $gw-badge-lightness-threshold) {
      @return $gw-badge-dark-text-color; // Lighter background, return dark color
    } @else {
      @return $gw-badge-light-text-color; // Darker background, return light color
    }
  }

  @each $type, $background in $gw-badge-types-backgrounds {
    @if map-has-key($gw-badge-types-text-color, $type) {
      &_type_#{$type} > &__content {
        background-color: $background;
        color: map-get($gw-badge-types-text-color, $type);
      }
    } @else {
      &_type_#{$type} > &__content {
        background-color: $background;
        color: gw-badge-select-text-color($background);
      }
    }
  }
  /* stylelint-enable */

  // FLAVORS
  &_flavor {
    &_largePriceDifference {
      .gwBadge__content {
        display: flex;
        align-items: center;
        justify-content: center;
        height: $gw-price-diff-indicator-height;
        width: $gw-price-diff-indicator-width;
        font-size: $font-m;
        font-weight: $gw-font-weight-bold;
        animation: indicator 0.5s 1;
        animation-fill-mode: forwards;
        animation-delay: $gw-indicator-animation-delay-seconds;
      }

      @include media('<=tabletPortrait') {
        display: none;
      }

      @keyframes indicator {
        from {
          opacity: 1;
        }

        to {
          opacity: 0;
        }
      }
    }
  }
}
