@import "base/common-dependencies";
@import '../../../mixinsAndVars';

.location {
  &__data {
    margin: $gwMobileScheduleDataItemVOffset 0;

    &:first-child {
      margin-top: 0;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__dataLabel {
    color: $grey-dark-60-color;
    font-size: $gw-font-xs;
  }
}
