@import "base/common-dependencies";

$gw-icon-color: #373737;
$gw-icon-background-color: #ececeb;
$gw-gray-font: #848484;
$gw-zero: 0;
$margin-bottom-grid: 4px;
$negative-margin-top: -14px;
$margin-top-period-box: 6px;
$padding-right: 55px;
$gw-alert-margin-top: 30px;
$gw-circle-size: 33px;
$gw-circle-border-radius: 50%;
$gw-icon-size: 16px;
$gw-spacer-margin-bottom: -50px;
$gw-line-width: 22px;
$gw-line-border-top: 1px;
$gw-line-margin: 9px;
$gw-wrapper-margin-top: 55px;
$gw-box-button-min-width: 165px;
$gw-box-button-margin-top: 15px;
$margin-top: 45px;
$gw-button-margin-left: 13px;
$gw-column-info-padding-left: 40px;
$gw-column-info-padding-left-tablet: 10px;
$gw-grid-margin-top: 36px;
$gw-grid-padding-bottom: 3px;
$gw-line-margin-top: 13px;
$gw-box-description-padding-top: 32px;
$gw-box-buttom-margin: 32px;
$gw-description-margin-top: 6px;
$gw-box-content-min-height: 180px;
$gw-box-content-min-height-desktop-big: 153px;
$gw-buttons-margin-top: 64px;
$gw-half-box-buttom: 82px;
$gw-box-content-line-height: 1.2;
$gw-box-content-border-radius: 3px;
$gw-box-content-width: 100%;

.fontChangeInCost {
  font-size: $gw-font-lg;
}

.circle {
  border-radius: $gw-circle-border-radius;
  width: $gw-circle-size;
  height: $gw-circle-size;
  background-color: $gw-icon-background-color;
  border-color: $gw-icon-background-color;
  display: flex;
  justify-content: center;
  align-items: center;

  i {
    color: $gw-icon-color;
    font-size: $gw-icon-size;
  }
}

.fontGrey {
  color: $gw-gray-font;
}

.spacer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: $gw-spacer-margin-bottom;

  @include media('<=tabletLandscape') {
    display: none;
  }
}

.boxContent {
  min-height: $gw-box-content-min-height-desktop-big;

  @include media('<=desktopSmall') {
    min-height: $gw-box-content-min-height;
  }

  line-height: $gw-box-content-line-height;
  position: relative;
  text-align: center;
  width: $gw-box-content-width;
  border: 1px solid #ddd;
  border-radius: $gw-box-content-border-radius;
}

.boxWrapper {
  border: $gw-zero;
  margin-top: $gw-wrapper-margin-top;
}

.boxDescription {
  font-size: $font-xl;
  display: block;
  padding-top: $gw-box-description-padding-top;
}

.line {
  border-top: $gw-line-border-top solid $grey-light-30-color;
  margin: $x $gw-line-margin;
  width: $gw-line-width;
  border-bottom: none;
  color: $gw-gray-font;
}

.columnInfo {
  padding-left: $gw-column-info-padding-left;

  @include media('<=tabletLandscape') {
    padding-left: $gw-column-info-padding-left-tablet;
  }
}

.boxButton {
  min-width: $gw-box-button-min-width;
  position: absolute;
  bottom: $gw-zero;
  left: calc(50% - #{$gw-half-box-buttom});
  margin-bottom: $gw-box-buttom-margin;
}

.lineMarginTop {
  @include media('>tabletLandscape') {
    margin-top: $gw-line-margin-top;
  }

  @include media('<=tabletLandscape') {
    margin-top: $x/2;
    margin-bottom: $x*2;
  }
}

.gwGrid {
  margin-top: $gw-grid-margin-top;
  padding-bottom: $gw-grid-padding-bottom;
}

.descriptionMarginTop {
  margin-top: $gw-description-margin-top;
}

.marginTop {
  margin-top: $margin-top;
}

.navigationButtonsWrapper {
  display: flex;
  justify-content: flex-end;
}

.buttonMarginLeft {
  margin-left: $gw-button-margin-left;
}
